@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.SearchInput {
  align-items: center;
  border: 1px solid $background_primary_dark_color;
  display: flex;
  height: 43px;
  justify-content: space-between;
  max-width: 332px;
  min-width: 150px;
  
  @include breakpoint-for(desktop-large) {
    width: 332px;
  }
  
  input {
    border: none;
    height: 100%;
    padding: 0 0 0 13px;
    width: 100%;
  }

  div {
    align-items: center;
    background-color: $background_primary_dark_color;
    display: flex;
    height: 43px;
    justify-content: center;
    width: 40px;
  }
}
