@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
@import "../../assets/scss/function";

.CaseList {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  margin: 0 auto;
  overflow-x: hidden;
  padding: 0 20px 10px;
  width: 100%;
  min-height: 100vh;

  @include breakpoint-for(desktop-small) {
    padding: 0 100px 40px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 0 190px 40px;
  }

  .container-caselist {
    align-items: flex-start;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 1920px;
    padding-top: 150px;
    // position: relative;
    width: 100%;

    .header-caselist {
      p {
        margin: 0;
        text-align: left;
      }

      .title {
        font-family: $madeoutersans_medium;
        font-size: pxToRem(50px);
        position: relative;
        width: max-content;

        @include breakpoint-for(desktop-small) {
          line-height: 150px;
          font-size: pxToRem(147px);
        }

        img {
          top: 0;
          max-width: 100px;
          position: absolute;
          right: -16%;

          @include breakpoint-for(desktop-small) {
            top: 0px;
            right: -12%;
            max-width: unset;
          }
        }
      }

      .description {
        font-family: $madeoutersans_regular;
        margin-top: 50px;
        max-width: 620px;

        @include breakpoint-for(desktop-small) {
          font-size: pxToRem(18px);
        }
      }
    }

    .content-cases {
      max-width: 1920px;
      width: 100%;
      margin-top: 75px;

      .title {
        border-bottom: 2px solid #00000084;
        font-family: $madeoutersans_bold;
        font-size: pxToRem(20px);
        padding-bottom: 8px;
        text-align: left;
        text-transform: uppercase;
      }

      .cases {
        display: grid;
        gap: 26px;
        grid-template-columns: 1fr;
        margin-top: 26px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        // overflow-y: auto;

        @include breakpoint-for(desktop-small) {
          grid-template-columns: repeat(2, 1fr);
        }

        .case {
          box-sizing: border-box;
          cursor: pointer;
          position: relative;
          width: 100%;

          .content-card {
            background-color: rgba(0, 0, 0, 0.6);
            bottom: 4px;
            color: #fff;
            display: flex;
            flex-direction: column;
            height: max-content;
            left: 0;
            padding: 30px 20px;
            position: absolute;
            right: 0;
            transition: opacity 0.3s ease, visibility 0.3s ease;
            z-index: 2;

            p {
              font-family: $madeoutersans_light;
              font-size: pxToRem(14px);
              margin: 0;
              text-align: left;
              width: 100%;

              &.title {
                font-family: $madeoutersans_medium;
                font-size: pxToRem(17px);
                margin-bottom: 0.5rem;
                text-transform: uppercase;
              }
            }
          }

          img {
            aspect-ratio: 1 / 1;
            height: 350px;
            object-fit: cover;
            width: 100%;

            @include breakpoint-for(desktop-small) {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
