@import "../../../assets/scss/variables";

.ImageWithBadge {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;

  &:hover,
  &.mobile {
    .caption {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s ease, visibility 0.3s ease;
    }

    &::before {
      content: "";
      position: absolute;
      display: flex;
      align-items: center;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100px;
      transition: background 0.3s ease;
      // background: linear-gradient(
      //   to bottom,
      //   rgba(0, 0, 0, 0),
      //   rgba(0, 0, 0, 0.6)
      // );
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 1;
    }
  }

  .image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    object-position: 30% 70%;
    transition: filter 0.3s ease;
  }

  .badge {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    background-color: $background_primary_light_color;
    color: $text_primary_dark_color;
    font-size: $xl_base_font_size;
    font-family: $roboto_medium;
    padding: 11px 24px;
    font-size: 0.75rem;
    z-index: 2;
  }

  .caption {
    position: absolute;
    bottom: 25px;
    left: 1.5rem;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 2;

    p {
      margin: 0;
      text-align: left;
      font-family: $madeoutersans_thin;
      font-size: pxToRem(18px);

      &.title {
        font-size: pxToRem(18px);
        font-family: $madeoutersans_medium;
        // line-height: calc($base_font_size);
        margin-bottom: 0.5rem;
      }
    }

    &.small {
      flex-direction: row;
      align-items: center;
      gap: 5px;

      p {
        margin: 0;

        &.title {
          margin: 0;
        }
      }
    }
  }

  &.mdaily {
    overflow: visible;
    display: flex;
    flex-direction: column;

    &:hover {
      &::before {
        background: unset;
      }
    }

    .image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      aspect-ratio: 1 / 1;
      object-position: center;
      transition: filter 0.3s ease;
    }

    .mdaily-caption {
      bottom: 0%;
      min-height: 30%;
      padding: 1rem 1.5rem 0;
      height: max-content;
      position: absolute;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      display: flex;
      flex-direction: column;
      transition: opacity 0.3s ease, visibility 0.3s ease;
      z-index: 2;

      p {
        margin: 0;
        text-align: left;
        font-family: $primary_font_demi;
        font-size: $sm_base_font_size;
        width: 100%;
      }
    }
  }
}
