@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.B2BCourse {
  padding: $nav_height 20px 68px;
  
  @include breakpoint-for (desktop-small) {
    padding: $nav_height 0 12px;
  }
  
  .course-info {
    display: grid;
    grid-template-areas: 'format'
    'title'
    'description'
    'info';
    justify-content: space-between;
    flex-direction: column;
    padding: 14px 0 30px;

    @include breakpoint-for(tablet) {
      padding: 24px 10px 100px;
    }

    @include breakpoint-for(desktop-small) {
      grid-template-areas: 'format description'
      'title description'
      'info description';
      grid-template-columns: 3fr 7fr;
      grid-template-rows: minmax(100px, auto) minmax(150px, auto) auto;
      padding: 24px $base_padding 100px;
    }

    @include breakpoint-for(desktop-large) {
      padding: 24px 0 128px $base_padding_desktop_large;
    }

    svg {
      @include breakpoint-for (desktop-small) {
        cursor: pointer;
        position: absolute;
        visibility: visible;
        width: 40px;
        z-index: 0;
      }

      &.RotatingText {
        aspect-ratio: 1 / 1;
        height: 200px;
        right: -80px;
        top: 300px;
        width: inherit;

        @include breakpoint-for(tablet) {
          height: 300px;
          right: -100px;
          top: 250px;
        }

        @include breakpoint-for(desktop-small) {
          height: 320px;
          right: -140px;
          top: 340px;
        }

        @include breakpoint-for(desktop-large) {
          height: 360px;
          right: -155px;
          top: 350px;
        }
      }
    }

    h2 {
        font-family: $primary_font_heavy;
        font-size: $xxs_title_font_size;
        grid-area: title;
        line-height: 35px;
        margin: 15px 0 41px;
        text-align: left;

        @include breakpoint-for (tablet) {
          font-size: $xs_title_font_size;
          line-height: 45px;
          margin: 15px 0 61px;
        }

        @include breakpoint-for (desktop-large) {
          font-size: $xl_title_font_size;
          line-height: 60px;
        }
      }

      .course-format {
        font-family: $primary_font_book;
        font-size: $xxxl_base_font_size;
        grid-area: format;
        height: fit-content;
        margin: 0 0 20px;
        text-align: left;

        @include breakpoint-for(tablet) {
          font-size: $xl_base_font_size;
        }
        
        @include breakpoint-for(desktop-small) {
          margin: 60px 0 0;
        }
        
        @include breakpoint-for(desktop-large) {
          margin: 68px 0 0;
        }
      }

    .course-info-details {
      margin-top: 50px;
      grid-area: info;
      width: 100%;

      @include breakpoint-for (desktop-small) {
        margin: unset;
      }

      h3 {
        font-family: $primary_font_heavy;
        font-size: $base_font_size;
        margin: 0;
        text-align: left;
      }

      p {
        font-family: $primary_font_book;
        font-size: $xxs_base_font_size;
        margin: 0 0 30px;
        text-align: left;

        @include breakpoint-for(tablet) {
          margin: 0 0 53px;
        }
      }
    }

    .course-info-description {
      display: flex;
      flex-direction: column;
      grid-area: description;
      height: fit-content;
      margin: 20px 0 0;
      position: relative;
      width: 100%;

      @include breakpoint-for (desktop-small) {
        margin: 120px 0 0;
        padding: 0 205px 0 50px;
      }

      @include breakpoint-for (desktop-large) {
        margin: 170px 0 0;
        padding: 0 285px 0 0;
      }

      h2 {
        display: none;
        font-family: $primary_font_heavy;
        font-size: $xxxxs_title_font_size;
        margin: 0 0 23px;
        text-align: left;
        visibility: hidden;
        
        @include breakpoint-for (tablet) {
          font-size: $xs_title_font_size;
          margin-bottom: 43px;
        }
        
        @include breakpoint-for (desktop-small) {
          display: block;
          font-size: $xl_title_font_size;
          margin: 0 0 63px;
          visibility: visible;
        }

        @include breakpoint-for (desktop-large) {
          font-size: $xxxl_title_font_size;
          margin: 0 0 73px;
        }
      }

      p {
        font-family: $primary_font_book;
        font-size: $base_font_size;
        letter-spacing: 0.4px;
        line-height: calc($xxs_base_font_size + 15px);
        margin: 0;
        text-align: left;
        width: 100%;

        @include breakpoint-for (desktop-small) {
          width: 730px;
        }

        @include breakpoint-for (desktop-large) {
          width: 856px;
        }
      }

      svg {
        bottom: -300px;
        position: absolute;
        right: 0;
        
        @include breakpoint-for (tablet) {
          bottom: -70px;
        }
        
        @include breakpoint-for (desktop-small) {
          bottom: -10px;
          right: 40px;
        }
        
        @include breakpoint-for (desktop-large) {
          bottom: -100px;
          right: 140px;
        }
      }
    }
  }

  .course-related {
    text-align: left;

    h3 {
      font-family: $primary_font_heavy;
      font-size: $xxxl_base_font_size;
      margin: 0 0 40px;
      text-align: left;

      @include breakpoint-for (desktop-small) {
        font-size: $xs_title_font_size;
        padding: 0 0 0 $base_padding;
      }
      
      @include breakpoint-for (desktop-large) {
        font-size: $xxl_title_font_size;
        margin: 128px 0 62px;
        padding: 0 0 0 $base_padding_desktop_large;
      }
    }

    a {
      align-items: center;
      background-color: $background_primary_light_color;
      border: 1px solid $background_primary_dark_color;
      color: $background_primary_dark_color;
      cursor: pointer;
      display: inline-flex;
      font-family: $primary_font_book;
      font-size: $base_font_size;
      justify-content: space-between;
      margin: 0 20px 20px 0;
      padding: 10px 22px;
      text-decoration: none;
      width: 100%;
      
      @include breakpoint-for (tablet) {
        margin: 0 20px 20px 0;
        width: inherit;
      }
      
      @include breakpoint-for (desktop-small) {
        margin: 0 26px 50px 0;
      }
      
      &:nth-child(2) {
        background-color: $background_primary_dark_color;
        color: $text_primary_light_color;
        
        @include breakpoint-for (desktop-small) {
          margin-left: $base_padding;
          margin-right: 26px;
        }

        @include breakpoint-for (desktop-large) {
          margin-left: $base_padding_desktop_large;
        }
      }

      img {
        height: 35px;
        margin-left: 10px;
      }
    }

    .related-courses {
      display: none;
      visibility: hidden;
      
      @include breakpoint-for(desktop-small) {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        justify-content: flex-start;
        visibility: visible;
        width: 100%;
      }
    }
  }
}
