
@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.B2CCourseCard {
  aspect-ratio: 1.005 / 1;
  background-color: $background_primary_dark_color;
  border: 1px solid $background_primary_light_color;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: $primary_font_book;
  justify-content: space-between;
  padding: 20px;
  width: 100%;

  @include breakpoint-for(tablet) {
    padding: 25px 24px 25px 40px;  
    width: 350px; 
   }

   @include breakpoint-for(desktop-small) {
    padding: 32px 35px 32px 43px;  
    width: 372px;
   }

   @include breakpoint-for(desktop-medium) {
    padding: 42px 47px 42px 57px;  
    width: 500px;
   }

   @include breakpoint-for(desktop-large) {
    height: 605px; 
    padding: 62px 71px 62px 86px;
    width: 632px;
   } 

  h2 {
    color: $text_primary_light_color;
    font-family: $primary_font_heavy;
    font-size: $xxxs_title_font_size;
    margin: 0;
    text-align: left;
    text-transform: uppercase;

    @include breakpoint-for(desktop-medium) {
      font-size: $title_font_size;
    }

    @include breakpoint-for(desktop-large) {
      font-size: $lg_title_font_size;
    } 
  }

  button {
    background: linear-gradient(to left, $background_primary_dark_color 50%, $background_primary_light_color 50%) right;
    background-size: 200% 100%;
    border: 1px solid $background_primary_light_color;
    border-radius: 34px;
    color: $text_primary_light_color;
    cursor: pointer;
    font-family: $primary_font_demi;
    font-size: $lg_base_font_size;
    padding: 10px 23px;
    transition:all 2s ease;
    width: fit-content;
    
    @include breakpoint-for(desktop-small) {
      padding: 17px 33px;
    }
    
    @include breakpoint-for(desktop-large) {
       font-size: $xxxs_title_font_size;
     }

     &:hover {
       background-position: left;
       color: $text_primary_dark_color;
       transition: all 1s ease;
     }
  }

  p {
    color: $text_secondary_light_color;
    font-family: $primary_font_book;
    margin: 0;
    text-align: left;

    &:first-child {
      font-size: $xxs_base_font_size;
  
      @include breakpoint-for(desktop-small) {
      font-size: $base_font_size;
      }
    }

    &:last-of-type {
      font-size: $xxxs_base_font_size;

      @include breakpoint-for(desktop-small) {
        font-size: $xs_base_font_size;
      }
    }
  }

  &.hover {
    filter: invert(1);
  }
}
