@import '../../../assets/scss/_variables';
@import '../../../assets/scss/_mixins';

.Ticket {
  align-items: center;
  display: flex;
  flex-direction: column;

  .container-ticket {
    display: flex;
    flex-direction: column;
    gap: 25px 0;
    margin-top: 60px;
    width: 100%;

    .input-box {
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      p {
        color: #e50000;
        font-family: "Roboto Regular";
        margin: 5px 0 0 0;
      }

      label {
        font-family: $primary_font_book;
        font-size: 18px;
        margin: 9px 0;
      }

      input {
        border: 1px solid #707070;
        font-family: $primary_font_book;
        font-size: 20px;
        height: 60px;
        padding: 14px 10px;
        width: 100%;

        &.error {
          border: 1px solid $error;
          outline: none;
        }
      }
    }

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      background: transparent;
      background: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') 97% 50% no-repeat transparent;
      border: 1px solid #707070;
      font-family: $primary_font_book;
      font-size: 20px;
      height: 60px;
      padding: 14px 20px;
      width: 100%;

      &.error {
        border: 1px solid $error;
        outline: none;
      }
    }

    .input-box-two {
      align-items: flex-start;
      display: flex;
      gap: 0 13px;

      label {
        text-align: left;
      }

      div {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        width: 100%;

        label {
          font-family: $primary_font_book;
          font-size: 18px;
          margin: 9px 0;
        }

        input {
          border: 1px solid #707070;
          font-family: $primary_font_book;
          font-size: 20px;
          height: 60px;
          padding: 14px 10px;
          width: 100%;
        }
      }
    }

    
  .input-box-three {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 0 13px;

    @include breakpoint-for(desktop-small) {
      align-items: flex-start;
      flex-direction: row;
    }

    label {
      text-align: left;
    }

    div {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      width: 100%;

      label {
        font-family: $primary_font_book;
        font-size: 18px;
        margin: 9px 0;
      }

      input {
        border: 1px solid #707070;
        font-family: $primary_font_book;
        font-size: 20px;
        height: 60px;
        padding: 14px 10px;
        width: 100%;
      }
    }
  }

    .two-box {
      display: flex;
      flex-direction: column;
      gap: 0 13px;
      width: 100%;

      .input-box {
        width: 100%;
      }

      @include breakpoint-for(mobile-large) {
        flex-direction: row;
      }
    }
  }

  .payment-button {
    display: flex;
    font-family: $primary_font_demi;
    font-size: 20px;
    height: 70px;
    justify-content: center;
    width: 100%;

    &.error {
      background-color: $error;
      border: none;
    }
  }
}
