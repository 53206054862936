@import "./fonts";

$line-height: 1.8rem;

$background_primary_dark_color: #000000;
$background_secondary_dark_color: #060606;
$background_tertiary_dark_color: #373737;
$background_primary_light_color: #ffffff;

$text_primary_dark_color: #000000;
$text_primary_light_color: #ffffff;
$text_secondary_dark_color: #282823;
$text_secondary_light_color: #c7c7c7;
$text_detail_color: #888888;
$title_color: #1a1a1a;
$text_green_color: #51a800;

$error: #e50000;

$primary_font_book: "Roboto Regular";
$primary_font_demi: "Roboto Bold";
$primary_font_heavy: "Roboto Black";
$primary_font_medium: "Roboto Medium";
$primary_font_light: "Roboto Light";

$roboto_light: Roboto-Light, sans-serif;
$roboto_bold: Roboto-Bold, sans-serif;
$roboto_regular: Roboto-Regular, sans-serif;
$roboto_thin: Roboto-Thin, sans-serif;
$roboto_medium: Roboto-Medium, sans-serif;
$roboto_italic: Roboto-Italic, sans-serif;
$ocr_bt: OCR-B-10-BT, sans-serif;
$display_playfair: PlayfairDisplay-BoldItalic, sans-serif;
$secondary_font: "Museo Sans 900";

$madeoutersans_light: MADEOuterSans-Light, sans-serif;
$madeoutersans_bold: MADEOuterSans-Bold, sans-serif;
$madeoutersans_regular: MADEOuterSans-Regular, sans-serif;
$madeoutersans_thin: MADEOuterSans-Thin, sans-serif;
$madeoutersans_medium: MADEOuterSans-Medium, sans-serif;
$madeoutersans_black: MADEOuterSans-Black, sans-serif;

$base_padding: 65px;
$base_padding_mobile: 24px;
$base_padding_tablet: 30px;
$base_padding_desktop_large: 85px;
$nav_height: 88px;
$nav_height_mobile: 80px;

$slogan_font_size_desktop_large: 246px;
$slogan_font_size_desktop_small: 206px;
$slogan_font_size_tablet: 146px;
$slogan_font_size_mobile: 70px;
$slogan_small_font_size_desktop_large: 79px;
$slogan_small_font_size_desktop_small: 69px;
$slogan_small_font_size_tablet: 59px;
$slogan_small_font_size_mobile: 33px;
$xxxxl_title_font_size: 75px;
$xxxl_title_font_size: 72px;
$xxl_title_font_size: 57px;
$xl_title_font_size: 51px;
$lg_title_font_size: 50px;
$title_font_size: 44px;
$sm_title_font_size: 43px;
$xs_title_font_size: 42px;
$xxs_title_font_size: 39px;
$xxxs_title_font_size: 32px;
$xxxxs_title_font_size: 31px;
$xxxl_base_font_size: 27px;
$xxl_base_font_size: 25px;
$xl_base_font_size: 23px;
$lg_base_font_size: 21px;
$base_font_size: 19px;
$sm_base_font_size: 18px;
$xs_base_font_size: 17px;
$xxs_base_font_size: 16px;
$xxxs_base_font_size: 14px;

$breakpoints: (
  "mobile-small": 400px,
  "mobile-large": 576px,
  "tablet": 768px,
  "tablet-portrait": 992px,
  "desktop-small": 1200px,
  "desktop-medium": 1400px,
  "desktop-large": 1600px,
  "desktop-extra-large": 1800px,
);
