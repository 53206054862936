@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.FinishPurchase {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 100px;
  justify-content: space-evenly;
  padding: 200px 30px;

  @include breakpoint-for(desktop-small) {
    align-items: flex-start;
    flex-direction: row;
    gap: 0 28px;
  }

  .container-finish {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    p {
      font-family: $primary_font_book;
      font-size: 28px;
      margin: 0;

      &.description {
        font-family: $primary_font_medium;
        font-size: 28px;
        margin-bottom: 40px;
        max-width: 526px;
        text-align: left;
      }

      span {
        font-family: $primary_font_demi;
      }
    }

    h3 {
      font-family: $primary_font_demi;
      font-size: 30px;
      margin: 15px 0 100px 0;
      text-align: left;

      @include breakpoint-for(desktop-medium) {
        font-size: 50px;
      }
    }

    a {
      text-decoration: none; 
      width: 100%;

      .finish-button {
        display: flex;
        font-family: $primary_font_demi;
        font-size: 20px;
        height: 70px;
        justify-content: center;
        margin-top: 100px;
        width: 100%;
      }
    }
  }

  .info-course {
    align-items: center;
    display: flex;
    width: 100%;

    @include breakpoint-for(mobile-large) {
      width: auto;
    }

    .box-info-course {
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      p {
        font-family: $primary_font_book;
        font-size: 25px;
        margin: 0;
      }

      h3 {
        font-family: $primary_font_demi;
        font-size: 35px;
        line-height: 80%;
        margin: 10px 0;
        text-align: left;

        @include breakpoint-for(desktop-medium) {
          font-size: 50px;
        }
      }

      .date-course {
        margin-top: 50px;

        p {
          font-family: $primary_font_book;
          font-size: 19px;
          margin: 0;
          text-align: left;
    
          span {
            color: $text_primary_dark_color;
            font-family: $primary_font_demi;
            font-size: 24px;
          }
        }
      }
    }

    img {
      display: none;
      max-height: 600px;
      max-width: 200px;

      @include breakpoint-for(mobile-large) {
        display: flex;
        font-size: 50px;
      }
    }
  }
}
