@mixin font-face(
  $font-family,
  $font-weight,
  $font-style,
  $font-display,
  $local,
  $woff2-suffix,
  $woff-suffix
) {
  @font-face {
    font-family: $font-family;
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: $font-display;
    unicode-range: U+000-5FF; /* Download only latin glyphs */
    src: local($local), url($woff2-suffix) format("woff2"),
      url($woff-suffix) format("woff");
  }
}

@mixin font-face-new($font-family, $font-suffix, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url($font-suffix) format("truetype"),
      url($font-suffix) format("opentype");
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@include font-face(
  "Roboto Black",
  900,
  normal,
  swap,
  "Roboto",
  "../fonts/Roboto-Black.woff2",
  "../fonts/Roboto-Black.woff"
);

@include font-face(
  "Roboto Regular",
  400,
  normal,
  swap,
  "Roboto",
  "../fonts/Roboto-Regular.woff2",
  "../fonts/Roboto-Regular.woff"
);

@include font-face(
  "Roboto Bold",
  600,
  normal,
  swap,
  "Roboto",
  "../fonts/Roboto-Bold.woff2",
  "../fonts/Roboto-Bold.woff"
);

@include font-face(
  "Roboto Medium",
  500,
  normal,
  swap,
  "Roboto",
  "../fonts/Roboto-Medium.woff2",
  "../fonts/Roboto-Medium.woff"
);

@include font-face(
  "Roboto Light",
  300,
  normal,
  swap,
  "Roboto",
  "../fonts/Roboto-Light.woff2",
  "../fonts/Roboto-Light.woff"
);

@include font-face(
  "Museo Sans 900",
  900,
  normal,
  swap,
  "Museo Sans",
  "../fonts/museosans-900.woff2",
  "../fonts/museosans-900.woff"
);

@include font-face-new(
  Roboto-Light,
  "../../assets/fonts/RobotoCondensed-Light.ttf",
  200,
  normal
);

@include font-face-new(
  Roboto-Thin,
  "../../assets/fonts/RobotoCondensed-Thin.ttf",
  100,
  normal
);

@include font-face-new(
  Roboto-Regular,
  "../../assets/fonts/RobotoCondensed-Regular.ttf",
  300,
  normal
);

@include font-face-new(
  Roboto-Bold,
  "../../assets/fonts/RobotoCondensed-Bold.ttf",
  800,
  normal
);

@include font-face-new(
  Roboto-Medium,
  "../../assets/fonts/RobotoCondensed-Medium.ttf",
  400,
  normal
);

@include font-face-new(
  Roboto-Italic,
  "../../assets/fonts/RobotoCondensed-Italic.ttf",
  100,
  normal
);

@include font-face-new(
  OCR-B-10-BT,
  "../../assets/fonts/OCR-B-10-BT.ttf",
  400,
  normal
);

@include font-face-new(
  PlayfairDisplay-BoldItalic,
  "../../assets/fonts/PlayfairDisplay-BoldItalic.ttf",
  600,
  normal
);

// NEW FONTS

@include font-face-new(
  MADEOuterSans-Light,
  "../../assets/fonts/MADEOuterSansLight.otf",
  200,
  normal
);

@include font-face-new(
  MADEOuterSans-Thin,
  "../../assets/fonts/MADEOuterSansThin.otf",
  100,
  normal
);

@include font-face-new(
  MADEOuterSans-Regular,
  "../../assets/fonts/MADEOuterSansRegular.otf",
  300,
  normal
);

@include font-face-new(
  MADEOuterSans-Medium,
  "../../assets/fonts/MADEOuterSansMedium.otf",
  400,
  normal
);

@include font-face-new(
  MADEOuterSans-Bold,
  "../../assets/fonts/MADEOuterSansBold.otf",
  800,
  normal
);

@include font-face-new(
  MADEOuterSans-Black,
  "../../assets/fonts/MADEOuterSansBlack.otf",
  800,
  normal
);
