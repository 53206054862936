@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.Solutions {
  align-items: center;
  background-color: $background_primary_light_color;
  display: grid;
  flex-direction: column;
  grid-template-areas: 'solutions';
  justify-content: flex-start;
  margin: $nav_height 0 0;
  min-height: calc(100vh - $nav_height);
  overflow-y: hidden;
  padding: 0 $base_padding_mobile;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    grid-template-areas: 'left img title right'
      'left img description right'
      'left img button right'
      'left platforms platforms right';
    grid-template-columns: .5fr 600px 3fr .5fr;
    grid-template-rows: .5fr minmax(216px, .7fr) .5fr .5fr;
    padding: 0 60px 0 $base_padding_desktop_large;
  }

  @include breakpoint-for(desktop-medium) {
    grid-template-columns: 1fr 670px 3fr 1fr;
  }

  @include breakpoint-for(desktop-large) {
    grid-template-columns: 1fr 830px 3fr 1fr;
  }

  img {
    grid-area: img;
    object-fit: cover;
    object-position: 0 0;
    width: 100%;

    &.lune {
      object-position: 0 -70px;
    }

    @include breakpoint-for(desktop-small) {
      aspect-ratio: 780 / 491;
      width: 600px;
    }

    @include breakpoint-for(desktop-medium) {
      aspect-ratio: 780 / 491;
      width: 670px;
    }

    @include breakpoint-for(desktop-large) {
      aspect-ratio: 780 / 491;
      width: 830px;
    }
  }

  h2 {
    align-self: flex-end;
    color: $text_primary_dark_color;
    font-family: $primary_font_heavy;
    grid-area: title;
    line-height: $xs_title_font_size;
    text-align: left;
    margin: 0;

    @include breakpoint-for(desktop-small) {
      font-size: $xxxxl_title_font_size;
      margin: 0 0 0 50px;
    }
  }

  p {
    align-self: start;
    font-family: $primary_font_book;
    font-size: $base_font_size;
    grid-area: description;
    line-height: $xxxl_base_font_size;
    margin: 30px 0 0;
    text-align: left;

    @include breakpoint-for(desktop-small) {
      margin: 30px 0 0 50px;
      width: 419px;
    }
  }

  &>svg {
    &:first-child {
      cursor: pointer;
      grid-area: left;
    }

    &:nth-child(6) {
      cursor: pointer;
      grid-area: right;
      justify-self: flex-end;
      transform: rotate(180deg);
    }
  }

  .rotating-text-wrapper {
    bottom: 0;
    right: 0;
    height: 277px;
    width: 277px;
    overflow: hidden;
    position: absolute;

    .RotatingText {
      bottom: -85px;
      right: -100px;
      z-index: 2;
    }
  }
  
  .solutions {
    grid-area: solutions;
  }

  & > a {
    align-self: flex-start;
    display: flex;
    grid-area: button;
    justify-content: space-between;
    margin: 0 0 0 50px;
    width: 396px;
  }
  
  a {
    align-items: center;
    background-color: $background_primary_dark_color;
    border: 1px solid $background_primary_dark_color;
    color: $background_primary_light_color;
    cursor: pointer;
    display: inline-flex;
    font-family: $primary_font_book;
    font-size: $base_font_size;
    justify-content: space-between;
    margin: 20px 0 80px;
    padding: 10px 22px;
    text-decoration: none;
    width: 100%;
    
    @include breakpoint-for (tablet) {
      margin: 0 20px 20px 0;
      width: inherit;
    }

    @include breakpoint-for (desktop-small) {
      align-self: flex-end;
      margin: 0 0 20px 50px;
      width: inherit;
    }

    svg {
      filter: invert(1);
      transform: rotate(180deg);
      width: 32px;
    }
  }

  .platforms {
    display: flex;
    grid-area: platforms;
    justify-content: space-between;
    justify-self: center;
    width: 100px;

    @include breakpoint-for(desktop-small) {
      width: 600px;
    }

    .Button {
      &.simple {
        border: 1px solid $background_primary_dark_color;
        border-radius: 50%;
        color: transparent;
        display: inline-block;
        height: 17px;
        overflow: hidden;
        width: 17px;

        @include breakpoint-for (desktop-small) {
          border: unset;
          border-bottom: 1px solid $background_primary_dark_color;
          border-radius: unset;
          color: $text_primary_dark_color;
          height: unset;
          overflow: unset;
          width: fit-content;
        }

        &.active {
          background-color: $background_primary_dark_color;

          @include breakpoint-for (desktop-small) {
            background-color: unset;
          }
        }
      }
    }
  }
}
