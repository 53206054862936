@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
@import "../../assets/scss/function";

.CaseDetails {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  margin: 0 auto;
  overflow-x: hidden;
  padding: 90px 20px 10px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    padding: 150px 100px 40px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 150px 190px 40px;
  }

  .container-case-details {
    align-items: flex-start;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 1920px;
    position: relative;
    width: 100%;

    .header-case-details {
      p {
        margin: 0;
        text-align: left;
      }

      .title {
        font-family: $madeoutersans_thin;
        font-size: pxToRem(25px);

        @include breakpoint-for(desktop-small) {
          font-size: pxToRem(49px);
        }
      }

      .description {
        font-family: $madeoutersans_regular;
        font-size: pxToRem(40px);
        line-height: 40px;
        margin-top: 15px;

        @include breakpoint-for(desktop-small) {
          line-height: 74px;
          font-size: pxToRem(65px);
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 50px;
      margin: 50px 0;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        flex-direction: row;
        gap: 150px;
        margin: 120px 0;
      }

      .left {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        width: 100%;

        @include breakpoint-for(desktop-small) {
          width: 50%;
        }

        p {
          font-family: $madeoutersans_regular;
          font-size: pxToRem(18px);
          margin-bottom: 30px;
          max-width: 620px;
          text-align: left;

          &.text-two {
            margin: 30px 0;

            @include breakpoint-for(desktop-small) {
              margin: 100px 0;
            }
          }

          @include breakpoint-for(desktop-small) {
            margin-bottom: 60px;
          }
        }

        img {
          max-height: 691px;
          object-fit: cover;
          width: 100%;

          &.image-two {
            max-height: 516px;
          }
        }

        span {
          font-family: $madeoutersans_medium;
          font-size: pxToRem(30px);
          margin: 80px 0;
          max-width: 620px;
          text-align: left;
        }
      }

      .right {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        width: 100%;

        @include breakpoint-for(desktop-small) {
          width: 50%;
        }

        img {
          max-height: 448px;
          object-fit: cover;
          width: 100%;
        }

        p {
          font-family: $madeoutersans_regular;
          font-size: pxToRem(18px);
          margin: 50px 0;
          max-width: 620px;
          text-align: left;

          @include breakpoint-for(desktop-small) {
            margin: 120px 0;
          }
        }
      }
    }
  }
}

.you-like-section-case {
  margin: 0 auto 100px;
  padding: 90px 20px 10px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    padding: 0 100px 40px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 0 190px 40px;
  }

  .container-you-like {
    max-width: 1920px;
    width: 100%;

    .title-like {
      border-bottom: 2px solid #00000084;
      font-family: $madeoutersans_bold;
      font-size: pxToRem(20px);
      padding-bottom: 8px;
      text-align: left;
      text-transform: uppercase;
    }

    .cards {
      display: flex;
      flex-direction: column;
      gap: 26px;
      height: 300px;
      margin-top: 26px;

      @include breakpoint-for(desktop-small) {
        flex-direction: row;
        height: 651px;
      }

      .card {
        cursor: pointer;
        position: relative;
        width: 100%;

        @include breakpoint-for(desktop-small) {
          width: 50%;
        }

        .content-card {
          background-color: rgba(0, 0, 0, 0.6);
          bottom: 4px;
          color: #fff;
          display: flex;
          flex-direction: column;
          height: max-content;
          left: 0;
          padding: 30px 20px;
          position: absolute;
          right: 0;
          transition: opacity 0.3s ease, visibility 0.3s ease;
          z-index: 2;

          @include breakpoint-for(desktop-small) {
            bottom: 0;
          }

          p {
            font-family: $madeoutersans_light;
            font-size: pxToRem(16px);
            margin: 0;
            text-align: left;
            width: 100%;

            &.title {
              font-family: $madeoutersans_medium;
              font-size: pxToRem(21px);
              margin-bottom: 0.5rem;
              text-transform: uppercase;
            }
          }
        }

        img {
          aspect-ratio: 1 / 1;
          height: 350px;
          object-fit: cover;
          width: 100%;

          @include breakpoint-for(desktop-small) {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
