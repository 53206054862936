@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.MultiEcommerce {
  align-items: flex;
  display: flex;
  flex-direction: column-reverse;
  padding: 120px 0;

  &.finish {
    display: none;
  }

  @include breakpoint-for(desktop-small) {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 0 28px;
    justify-content: flex-start;
    padding: 120px 100px;
  }

  .container-border {
    border-radius: 13px;
    border: 1px solid #707070;
    padding: 30px;
  }

  .container-purchase {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 19px;
    border: none;
    max-width: 811px;
    padding: 10px;
    width: 100%;

    .info-course {
      display: flex;

      img {
        max-height: 80px;
        max-width: 80px;

        @include breakpoint-for(desktop-small) {
          max-height: 200px;
          max-width: 200px;
        }
      }

      .text-box {
        display: flex;
        flex-direction: column;
        justify-content: flex;
        margin-left: 23px;

        p {
          font-family: $primary_font_book;
          font-size: 12px;
          margin: 0;
          text-align: left;

          &:first-child {
            font-family: $primary_font_demi;
            font-size: 17px;
            letter-spacing: 0.63px;
            text-transform: uppercase;
          }

          &:last-child {
            font-family: $primary_font_demi;
            font-size: 18px;
          }

          &.times-text {
            font-size: 14px;
            letter-spacing: 0.32px;
            margin: 10px 0;

            span {
              font-family: $primary_font_book;
              font-size: 25px;
            }
          }
        }

        .open-description-mobile {
          background: none;
          border-bottom: 1px solid black;
          border: none;
          cursor: pointer;
          font-family: $primary_font_book;
          font-size: 18px;
          margin-top: 20px;
          padding: 0;
          text-align: left;

          @include breakpoint-for(desktop-small) {
            visibility: hidden;
          }
        }

        @include breakpoint-for(desktop-small) {
          p {
            font-size: 20px;

            &:first-child {
              font-size: 23px;
            }

            &:last-child {
              font-family: $primary_font_book;
              font-size: 23px;
            }
          }
        }
      }
    }

    .container-questions {
      display: flex;
      flex-direction: column;
      gap: 40px;
      justify-content: flex-start;
      margin: 40px 0;

      .questions {
        border-radius: 13px;
        border: 1px solid #707070;
        padding: 30px;
        width: 100%;

        .info-question {
          align-items: flex-start;
          display: flex;
          flex-direction: column;

          h2 {
            font-family: $primary_font_heavy;
            font-size: 1.5rem;
            margin-bottom: 15px;
            margin: 0;
            text-align: left;
          }

          p {
            font-family: $primary_font_book;
            font-size: 18px;
            margin: 0;
            margin: 5px 0;
          }

          h3 {
            font-family: $primary_font_light;
            font-size: 32px;
            margin: 0;
            margin: 60px 0 40px 0;
            text-align: left;
          }

          @include breakpoint-for(mobile-small) {
            h2 {
              font-size: 2.2rem;
            }
          }

          @include breakpoint-for(desktop-small) {
            h2 {
              font-size: 48px;
            }
          }
        }

        .gap-questions {
          display: flex;
          flex-direction: column;
          gap: 78px 0;
        }
      }

      .title-question {
        font-family: $primary_font_book;
        font-size: 20px;
        margin: 0;
        margin: 5px 0 20px 0;
        text-align: left;
        text-transform: lowercase;
      }
    }

    .container-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: flex-start;
      margin-top: 80px;

      @include breakpoint-for(desktop-small) {
        gap: 0 15px;
        margin-top: 0px;
      }

      button {
        align-items: center;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 6px;
        border: 1px solid #707070;
        cursor: pointer;
        display: flex;
        height: 70px;
        justify-content: center;
        padding: 15px;
        width: 150px;

        svg {
          margin-right: 10px;
        }

        &.active {
          border: 2px solid #000000;
        }

        &.pix {
          opacity: 0.4;
        }

        @include breakpoint-for(desktop-small) {
          width: auto;
        }
      }
    }
  }

  .wallet-cart {
    align-items: center;
    border-radius: 19px;
    border: 1px solid #707070;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 25px 10px;
    max-width: 433px;
    width: 95vw;
    z-index: 3;

    .title-product {
      align-items: center;
      background-color: $text_primary_dark_color;
      border-radius: 19px 19px 0 0;
      display: flex;
      justify-content: space-between;
      min-height: 60px;
      padding: 10px 25px;
      width: 100%;

      p {
        align-items: center;
        color: white;
        display: flex;
        font-family: $primary_font_book;
        font-size: 18px;
        margin: 0;
        text-align: left;

        .car-icon {
          height: 23px;
          margin-right: 20px;
          width: 29px;
        }

        span {
          font-size: 16px;
          text-transform: uppercase;
        }
      }
    }

    .sub-details-product {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 30px 25px;
      width: 100%;

      .infos-product {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 10px 0;

        p {
          font-family: $primary_font_book;
          font-size: 17px;
          margin: 0;
          text-align: left;
          width: 80%;

          strong {
            font-family: $primary_font_demi;
          }
        }
      }
    }

    .purchase-course-button {
      background-color: $text_green_color;
      border: none;
      color: $text_primary_light_color;
      cursor: pointer;
      font-family: $primary_font_demi;
      font-size: 23px;
      height: 68px;
      width: 101%;
    }

    @include breakpoint-for(desktop-medium) {
      left: 65%;
      position: fixed;
      top: 16.5%;
    }

    @include breakpoint-for(desktop-large) {
      left: 53%;
      top: 15%;
    }
  }
}

.active-modal {
  -webkit-tap-highlight-color: transparent;
  background-color: rgba(0, 0, 0, 0.5);
  inset: 0px;
  position: fixed;
  z-index: 0;
}