@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Input {
  margin: 10px 0;
  position: relative;

  label {
    color: white;
    display: flex;
    font-weight: 400;
    letter-spacing: 0.1rem;
    line-height: $line-height;

    .tooltip {
      display: flex;
      position: relative;

      svg {
        fill: white;
        margin: 0 10px;
        width: 15px;
        z-index: 1;
      }

      & > div {
        color: white;
        background-color: grey;
        left: -75px;
        max-width: 85vw;
        opacity: 0;
        padding: 10px 20px;
        position: absolute;
        top: 30px;
        transition: 0.4s;
        width: max-content;
        z-index: 2;

        @include breakpoint-for(tablet) {
          left: 30px;
          max-width: 420px;
          top: 0;
        }

        p {
          color: white;
          font-family: $primary_font_book;
          font-size: $sm_base_font_size;
          margin: 0;
        }

        &.visible {
          opacity: 1;
        }
      }
    }
  }

  .input-container {
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;

    input {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.55);
      box-sizing: border-box;
      color: rgba(255, 255, 255, 0.55);
      outline: none;
      padding: 15px 15px 15px 0;
      width: 100%;
      transition: 0.2s;

      &::placeholder {
        color: rgba(255, 255, 255, 0.55);
        font-size: $base_font_size;
        letter-spacing: 0.0225rem;
        line-height: $line-height;
        font-style: italic;
      }

      &.show-error {
        border-color: $error;

        &::placeholder {
          color: $error;
        }
      }

      &:disabled {
        border-color: transparent;
      }
    }
  }
  & > .feedback-message {
    color: white;
    font-size: 0.7rem;
    margin: 5px 5px 0 0;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }
}
