@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.MDailyHome {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 90px 20px;

  @include breakpoint-for(desktop-small) {
    padding: 150px 100px 100px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 150px 190px 100px;
  }
  .mdaily-home-content {
    align-items: flex-start;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    position: relative;
    width: 100%;

    .flutuant-text {
      font-family: $madeoutersans_bold;
      font-size: pxToRem(20px);

      @include breakpoint-for(tablet) {
        font-size: pxToRem(20px);
        left: -320px;
        position: absolute;
        text-wrap: nowrap;
        top: 230px;
        transform: matrix(0, -1, 1, 0, 0, 0);
      }
    }

    .button-daily {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      width: 100%;

      button {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid black;
        cursor: pointer;
        font-family: $madeoutersans_bold;
        font-size: pxToRem(20px);
        padding: 3px 0;
        width: max-content;
      }
    }

    .container-mdaily {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;

      .box-mdaily {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        width: 100%;

        .box {
          flex: 1 1 calc(100%);
        }

        @include breakpoint-for(tablet) {
          .box {
            flex: 1 1 calc(50% - 20px);
          }
        }
        @include breakpoint-for(desktop-small) {
          flex-direction: row;
          .box {
            width: 10%;
            flex: 20%;
          }
        }
      }
    }

    .container-apresentation-mdaily {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 50px 0 0;
      width: 100%;

      h3 {
        font-size: pxToRem(30px);
        font-family: $madeoutersans_bold;
        text-align: left;

        @include breakpoint-for(tablet) {
          font-size: pxToRem(60px);
        }

        @include breakpoint-for(desktop-extra-large) {
          font-size: pxToRem(80px);
        }
      }

      .circular-text-container {
        align-items: center;
        display: flex;
        height: 150px;
        justify-content: center;
        position: relative;
        width: 150px;

        .circle-svg {
          animation: rotate-text 10s linear infinite;
          height: 100%;
          transform-origin: center;
          width: 100%;
        }

        .circle-text {
          fill: black;
          font-family: $madeoutersans_regular;
          font-size: pxToRem(11px);
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        .arrow {
          position: absolute;
          width: 30px;
        }

        @keyframes rotate-text {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
