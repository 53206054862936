@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.feedcases-container {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 90px 20px;

  @include breakpoint-for(desktop-small) {
    padding: 150px 100px 100px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 150px 190px 100px;
  }

  .FeedCases {
    align-items: flex-start;
    background-color: $background_primary_light_color;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    min-height: 100vh;
    position: relative;
    width: 100%;

    .flutuant-text {
      font-family: $madeoutersans_bold;
      font-size: pxToRem(20px);
      text-wrap: nowrap;

      @include breakpoint-for(tablet) {
        left: -190px;
        position: absolute;
        top: 110px;
        transform: matrix(0, -1, 1, 0, 0, 0);
      }
    }

    .container-cases {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 0 auto;
      width: 100%;

      .box-cases {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 20px;
        width: 100%;

        @include breakpoint-for(tablet) {
          flex-direction: row;
        }

        .left-cases,
        .right-cases {
          display: flex;
          flex-direction: column;
          gap: 20px;
          justify-content: flex-start;
          width: 100%;

          @include breakpoint-for(tablet) {
            width: 50%;
          }
        }
      }
    }

    .container-partners {
      -webkit-box-align: center;
      align-items: center;
      display: flex;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      justify-content: center;
      justify-content: space-between;
      margin: 130px 0 0 0;
      width: 100%;

      .partner-image {
        filter: grayscale(100%);
        height: 70px;
        max-width: 100px;
        transition: filter 0.3s ease;

        @include breakpoint-for(mobile-large) {
          height: 50px;
          max-width: 80px;
        }

        &:hover {
          filter: grayscale(0%);
        }
      }
    }

    .assinature-mdaily {
      background-color: $background_primary_light_color;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 40px;
      height: 314px;
      justify-content: flex-start;
      margin: 0 auto;
      max-width: 1400px;
      padding: 50px 20px 100px;
      position: relative;
      width: 100%;
      z-index: 1;

      @include breakpoint-for(tablet) {
        height: fit-content;
        padding: 40px 20px;
      }

      @include breakpoint-for(desktop-small) {
        gap: 50px;
      }

      h3 {
        color: $text_primary_dark_color;
        font-family: $roboto_bold;
        font-size: $base_font_size;
        font-weight: 900;
        line-height: calc($lg_base_font_size);
        margin: 0;
        text-align: left;

        @include breakpoint-for(tablet) {
          font-size: $xxxs_title_font_size;
          line-height: calc($xxxs_title_font_size);
        }

        @include breakpoint-for(desktop-small) {
          font-size: $xxs_title_font_size;
          line-height: calc($xxs_title_font_size);
        }
      }

      button {
        align-items: center;
        background-color: $background_primary_light_color;
        border: unset;
        color: $text_primary_dark_color;
        cursor: pointer;
        display: flex;
        font-family: $roboto_regular;
        font-size: $base_font_size;
        gap: 15px;
        justify-content: flex-end;

        div {
          align-items: center;
          border-radius: 50%;
          border: 2px solid $text_primary_dark_color;
          display: flex;
          height: 40px;
          justify-content: center;
          width: 40px;
        }

        @include breakpoint-for(tablet) {
          font-size: $lg_base_font_size;
        }
      }
    }

    .container-projects {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 0 auto;
      max-width: 1400px;
      padding: 0 20px 20px;
      width: 100%;

      @include breakpoint-for(tablet) {
        flex-direction: row;
        padding: 0 20px;
      }

      .left-cases,
      .right-cases {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: flex-start;
        width: 100%;

        @include breakpoint-for(tablet) {
          width: 50%;
        }
      }
    }
  }
}
