@import "../../assets/scss/variables";
@import '../../assets/scss/mixins';

.NotFoundPage{
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $nav_height 30px;

  @include breakpoint-for(desktop-small) {
    padding: $nav_height $base_padding;
  }

  & > div{
    display: flex;
    flex-direction: column;

    & > h2 {
      font-family: $primary_font_heavy;
      font-size: 5rem;
      font-weight: 400;
      margin: 30px 0;
      text-align: left;

      &:first-child{
        margin: 30px 0 0;
      }

      &:nth-child(2){
        margin: 10px 0 30px;
      }

      @include breakpoint-for(lg) {
        font-size: 6rem;
      }
    }
  }

  ul {
    li {
      font-family: $primary_font_book;
      font-size: $base_font_size;
      text-align: left;
    }
  }

  p {
    color: $text_primary_dark_color;
    font-family: $primary_font_book;
    font-size: $base_font_size;
    margin: 5px 0;
    text-align: left;

    &:nth-child(2){
      font-size: 2rem;
      margin-bottom: 10px ;
    }

    @include breakpoint-for(desktop-small) {
      text-align: justify;
    }

    span{
      font-weight: 800;
    }

    a {
      color: black;
    }
  }
}
