 @import '../../../assets/scss/variables';

.RotatingText {
  animation: rotate 80s infinite linear;
  position: absolute;
  z-index: 0;
  
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(-360deg);
    }
  }
  
  &.inverted {
    filter: invert(1);
  }
}
