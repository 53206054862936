@import '../../../assets/scss/_variables';
@import '../../../assets/scss/_mixins';

.Cart {
  align-items: flex-start;
  background-color: white;
  box-shadow: -10px 8px 19px #00000029;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  max-width: 450px;
  padding: 30px 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;

  .info-cart {
    display: flex;
    justify-content: center;
    margin: 0 0 5px 0;
    width: 100%;

    button {
      background: none;
      border: none;
      color: inherit;
      cursor: pointer;
      font: inherit;
      left: -18%;
      outline: inherit;
      padding: 0;
      position: relative;

      img {
        height: 19px;
        width: 10px;
      }
    }

    h5 {
      font-family: $primary_font_demi;
      font-size: 27px;
      margin: 0 0 5px 0;
      text-transform: uppercase;
    }

    @include breakpoint-for(mobile-small) {
      button {
        left: -23%;
      }
    }
  }

  .container-cart {
    height: 70vh;
    max-width: 1519px;
    overflow-y: scroll;
    padding: 0 20px;
    width: 100%;

    .product-details {
      border-radius: 13px;
      border: 1px solid #707070;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 25px 0;
      min-height: 200px;

      .title-product {
        align-items: center;
        background-color: $text_primary_dark_color;
        border-radius: 13px 13px 0 0;
        display: flex;
        justify-content: space-between;
        min-height: 60px;
        padding: 10px 25px;

        p {
          color: white;
          font-family: $primary_font_book;
          font-size: 17px;
          margin: 0;
          text-align: left;

          span {
            font-size: 16px;
            text-transform: uppercase;
          }
        }
      }

      .sub-details-product {
        display: flex;
        justify-content: space-between;
        padding: 20px 25px;

        .infos-product {
          p {
            color: grey;
            font-family: $primary_font_book;
            font-size: 16px;
            margin: 7px 0;
            text-align: left;

            &.green {
              color: $text_green_color;
            }

            &:last-child {
              font-style: italic;
            }
          }
        }

        .increment-product {
          align-items: flex-end;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .contain-product {
            align-items: center;
            display: flex;
            justify-content: space-between;

            p {
              font-family: $primary_font_demi;
              font-size: 16px;
              margin: 0 10px;
              text-transform: uppercase;
            }
          }

          button {
            background: none;
            border: none;
            color: inherit;
            cursor: pointer;
            font: inherit;
            outline: inherit;
            padding: 0;

            img {
              height: 19px;
              width: 19px;
            }
          }
        }
      }
    }

    hr {
      border-top: 1px solid black;
      border: 0;
      width: 95%;

      &:last-child {
        display: none;
      }
    }

    &::-webkit-scrollbar{
      display: none;
      width: 0 !important
    }
  }

  .subtotal-products {
    align-items: flex-start;
    box-shadow: -10px -3px 19px #00000029;
    display: flex;
    flex-direction: column;
    height: 110px;
    justify-content: center;
    padding: 0;
    width: 100%;

    p {
      font-family: $primary_font_medium;
      font-size: 22px;
      margin: 0;
      padding: 0 81px;

      strong {
        font-family: $primary_font_demi;
        font-size: 27px;
      }
    }
  }

  .finish-products {
    width: 100%;

    button {
      background: $background_primary_dark_color;
      border-radius: 20px;
      border: 1px solid #FFFFFF;
      color: $text_primary_light_color;
      cursor: pointer;
      font-family: $primary_font_demi;
      font-size: 20px;
      height: 80px;
      margin-top: 40px;
      padding: 20px 29px;
      text-align: center;
      width: 90%;
    }
  }
}

// #rd-floating_button-kiq9ncir {
//   visibility: hidden;
// }
