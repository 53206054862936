@import "src/assets/scss/_variables.scss";
@import "src/assets/scss/mixins.scss";
@import "src/assets/scss/function.scss";

.Footer {
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;

  @include breakpoint-for(desktop-small) {
    padding: 0 100px 100px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 0 190px 100px;
  }

  .footer-content {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    max-width: 1920px;
    width: 100%;

    .footer-left {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .footer-logo {
        h1 {
          font-family: $madeoutersans_bold;
          font-size: pxToRem(27px);
          margin: 0;
        }
      }

      .footer-links {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: space-between;
        margin-top: 5px;
        margin: 0 0 20px 0;
        width: 100%;

        @include breakpoint-for(desktop-small) {
          flex-direction: row;
        }

        .button-list {
          display: flex;
          flex-direction: column;

          li {
            cursor: pointer;
            margin: 0 0 20px 0;
            text-transform: uppercase;
            font-size: pxToRem(15px);
            text-align: left;

            @include breakpoint-for(tablet) {
              font-size: pxToRem(18px);
            }

            &:hover {
              color: #e40030;
            }
          }
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            font-family: $madeoutersans_regular;
            font-size: pxToRem(15px);
            font-weight: normal;
            margin: 0 0 10px 0;
            text-align: left;

            @include breakpoint-for(tablet) {
              font-size: pxToRem(17px);
            }
          }
        }
      }

      .footer-logos {
        align-items: center;
        display: flex;
        gap: 20px;

        .ic--baseline-whatsapp {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M19.05 4.91A9.82 9.82 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01m-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18l-3.12.82l.83-3.04l-.2-.31a8.26 8.26 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24c2.2 0 4.27.86 5.82 2.42a8.18 8.18 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23m4.52-6.16c-.25-.12-1.47-.72-1.69-.81c-.23-.08-.39-.12-.56.12c-.17.25-.64.81-.78.97c-.14.17-.29.19-.54.06c-.25-.12-1.05-.39-1.99-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.14-.25-.02-.38.11-.51c.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31c-.22.25-.86.85-.86 2.07s.89 2.4 1.01 2.56c.12.17 1.75 2.67 4.23 3.74c.59.26 1.05.41 1.41.52c.59.19 1.13.16 1.56.1c.48-.07 1.47-.6 1.67-1.18c.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
          display: inline-block;
          height: 30px;
          width: 30px;

          @include breakpoint-for(tablet) {
            height: 50px;
            width: 50px;
          }
        }

        .mdi--instagram {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
          display: inline-block;
          height: 30px;
          width: 30px;

          @include breakpoint-for(tablet) {
            height: 50px;
            width: 50px;
          }
        }

        .mingcute--youtube-fill {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z'/%3E%3Cpath fill='%23000' d='M12 4c.855 0 1.732.022 2.582.058l1.004.048l.961.057l.9.061l.822.064a3.8 3.8 0 0 1 3.494 3.423l.04.425l.075.91c.07.943.122 1.971.122 2.954s-.052 2.011-.122 2.954l-.075.91l-.04.425a3.8 3.8 0 0 1-3.495 3.423l-.82.063l-.9.062l-.962.057l-1.004.048A62 62 0 0 1 12 20a62 62 0 0 1-2.582-.058l-1.004-.048l-.961-.057l-.9-.062l-.822-.063a3.8 3.8 0 0 1-3.494-3.423l-.04-.425l-.075-.91A41 41 0 0 1 2 12c0-.983.052-2.011.122-2.954l.075-.91l.04-.425A3.8 3.8 0 0 1 5.73 4.288l.821-.064l.9-.061l.962-.057l1.004-.048A62 62 0 0 1 12 4m-2 5.575v4.85c0 .462.5.75.9.52l4.2-2.425a.6.6 0 0 0 0-1.04l-4.2-2.424a.6.6 0 0 0-.9.52Z'/%3E%3C/g%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
          display: inline-block;
          height: 30px;
          width: 30px;

          @include breakpoint-for(tablet) {
            height: 50px;
            width: 50px;
          }
        }

        .ri--linkedin-fill {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M6.94 5a2 2 0 1 1-4-.002a2 2 0 0 1 4 .002M7 8.48H3V21h4zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
          display: inline-block;
          height: 30px;
          width: 30px;

          @include breakpoint-for(tablet) {
            height: 50px;
            width: 50px;
          }
        }
      }
    }
  }

  .footer-bottom {
    border-top: 1px solid black;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: space-between;
    margin-top: 35px;
    padding: 35px 0;
    width: 100%;

    @include breakpoint-for(desktop-small) {
      flex-direction: row;
    }

    p {
      cursor: pointer;
      font-family: $madeoutersans_regular;
      font-size: pxToRem(15px);
      margin: 0;
      text-align: left;

      @include breakpoint-for(desktop-small) {
        font-size: pxToRem(18px);
      }
    }
  }
}
