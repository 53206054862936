@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.CaseCompleted {
  width: 100%;
  box-sizing: border-box !important;

  .header-case {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    object-position: center;

    @include breakpoint-for(tablet) {
      height: 95vh;
    }
  }

  .container-title-case {
    box-sizing: border-box;
    padding: 50px 20px;

    @include breakpoint-for(tablet) {
      padding: 100px 55px;
    }

    .box-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-bottom: 100px;
      border-bottom: 1px solid #d4d4d4;

      h1 {
        margin: 0;
        font-size: 25px;
        text-align: left;
        font-family: $roboto_bold;

        @include breakpoint-for(tablet) {
          text-align: center;
          font-size: 45px;
        }
      }

      p {
        margin: 0;
        font-size: 22px;
        text-align: left;
        font-family: $primary_font_book;

        @include breakpoint-for(tablet) {
          font-size: 34px;
        }
      }
    }

    .challenge-title {
      display: flex;
      align-items: flex-start;
      padding: 50px 0;
      box-sizing: border-box;
      flex-direction: column;
      gap: 20px;

      @include breakpoint-for(tablet) {
        padding: 100px 0;
        gap: 10%;
        align-items: center;
        flex-direction: row;
      }

      &.no-image {
        padding: 100px 0 0 0;
      }

      h2 {
        margin: 0;
        font-size: 27px;
        white-space: nowrap;
        width: max-content;
        font-family: $primary_font_demi;

        @include breakpoint-for(desktop-large) {
          font-size: 35px;
        }
      }

      p {
        margin: 0;
        text-align: left;
        line-height: 30px;
        font-size: 17px;
        font-family: $primary_font_book;

        @include breakpoint-for(desktop-large) {
          line-height: 35px;
          font-size: 22px;
        }
      }
    }
  }

  .container-challenge-image {
    width: 100%;
    height: 50vh;
    min-height: 400px;

    @include breakpoint-for(tablet) {
      height: 95vh;
      min-height: 800px;
    }

    .challenge-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .challenge-resolve-container {
    background-color: #001694;
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    box-sizing: border-box;
    color: white;
    gap: 70px;

    @include breakpoint-for(tablet) {
      padding: 100px 55px;
    }

    .box-challenge-idea,
    .box-challenge-result {
      display: flex;
      gap: 20px;
      flex-direction: column;

      @include breakpoint-for(tablet) {
        gap: 20%;
        flex-direction: row;
      }

      h2 {
        margin: 0;
        font-size: 27px;
        white-space: nowrap;
        width: 100px;
        font-family: $primary_font_demi;

        @include breakpoint-for(desktop-large) {
          font-size: 35px;
        }
      }

      p {
        margin: 0;
        text-align: left;
        line-height: 30px;
        font-size: 17px;
        font-family: $primary_font_light;

        @include breakpoint-for(desktop-large) {
          line-height: 35px;
          font-size: 22px;
        }
      }
    }
  }

  .cases-recommendation {
    background-color: #303030;
    padding: 50px 20px;
    box-sizing: border-box;

    @include breakpoint-for(tablet) {
      padding: 100px 55px;
      height: 90vh;
    }

    .title-recommendation {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include breakpoint-for(tablet) {
        flex-direction: row;
      }

      h4 {
        margin: 0;
        font-size: 27px;
        white-space: nowrap;
        width: 100px;
        font-family: $primary_font_demi;
        color: white;

        @include breakpoint-for(desktop-large) {
          font-size: 35px;
        }
      }

      button {
        align-items: center;
        background-color: transparent;
        border: unset;
        color: $text_primary_light_color;
        cursor: pointer;
        display: flex;
        font-family: $primary_font_book;
        font-size: 20px;
        gap: 15px;

        div {
          align-items: center;
          border-radius: 50%;
          border: 2px solid $text_primary_light_color;
          display: flex;
          height: 40px;
          justify-content: center;
          width: 40px;
        }

        @include breakpoint-for(desktop-large) {
          font-size: $lg_base_font_size;
        }
      }
    }

    .box-recommendation {
      display: flex;
      gap: 20px;
      width: 100%;
      padding-top: 60px;
      flex-direction: column;

      .ImageWithBadge {
        width: 100% !important;
        height: 30vh !important;
      }

      @include breakpoint-for(tablet) {
        flex-direction: row;

        .ImageWithBadge {
          height: 60vh !important;
        }
      }

      // @include breakpoint-for(tablet) {
      //   width: 50%;
      // }
    }
  }
}
