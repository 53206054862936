@import "../../../assets/scss/_variables";
@import "../../../assets/scss/_mixins";
@import "../../../assets/scss/function";

.navbar-container {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 15px 0 32px;
  width: 100%;
  position: fixed;
  z-index: 100;

  @include breakpoint-for(desktop-small) {
    padding: 32px 100px 32px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 32px 190px 32px;
  }

  .Navbar {
    align-items: center;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    max-width: 1920px;
    position: relative;
    width: 100%;
    margin: 0 auto;

    .header-navbar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;

      @include breakpoint-for(desktop-small) {
        padding: 0;
        width: min-content;
      }
      a {
        text-decoration: none;

        h1 {
          z-index: 3;
          color: black;
          font-family: $madeoutersans_bold;
          font-size: pxToRem(24px);
          letter-spacing: 0;
          border: unset;
          margin: 0;
          text-transform: uppercase;
          width: fit-content;
        }
      }

      button {
        background-color: transparent;
        border: unset;

        .material-symbols--menu-rounded {
          display: inline-block;
          width: 35px;
          height: 35px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M4 18q-.425 0-.712-.288T3 17t.288-.712T4 16h16q.425 0 .713.288T21 17t-.288.713T20 18zm0-5q-.425 0-.712-.288T3 12t.288-.712T4 11h16q.425 0 .713.288T21 12t-.288.713T20 13zm0-5q-.425 0-.712-.288T3 7t.288-.712T4 6h16q.425 0 .713.288T21 7t-.288.713T20 8z'/%3E%3C/svg%3E");
        }

        .line-md--menu-to-close-alt-transition {
          display: inline-block;
          width: 35px;
          height: 35px;
          --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275t-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275t.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7t-.7.275t-.7-.275z'/%3E%3C/svg%3E");
          background-color: currentColor;
          -webkit-mask-image: var(--svg);
          mask-image: var(--svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
        }

        @include breakpoint-for(desktop-small) {
          display: none;
        }
      }
    }

    .container-buttons {
      display: none;
      gap: 30px;

      @include breakpoint-for(desktop-small) {
        gap: 71px;
        display: flex;
      }

      // SUBMENU NAVIGATION
      .navigation {
        display: inline-block;
        position: relative;

        .menu-link {
          background-color: #000;
          color: white;
          cursor: pointer;
          display: inline-block;
          font-weight: bold;
          padding: 10px;
          text-decoration: none;

          &.open {
            background-color: #f73b56;
          }
        }

        .dropdown-menu {
          left: 0;
          opacity: 0;
          padding: 5px 0;
          position: absolute;
          top: 40px;
          transform: translateY(-10px);
          transition: opacity 0.3s ease, transform 0.3s ease,
            visibility 0.3s ease;
          visibility: hidden;
          width: 250px;
          z-index: 20;

          &.visible {
            opacity: 1;
            transform: translateY(0);
            visibility: visible;
          }

          a {
            background-color: #f73b56;
            border-top: 3px solid black;
            color: black;
            display: block;
            font-family: $madeoutersans_regular;
            font-size: pxToRem(13px);
            padding: 10px;
            text-align: left;
            text-decoration: none;

            &:hover {
              color: white;
            }
          }

          .menu-item {
            position: relative;

            .submenu-link {
              cursor: pointer;

              &.active {
                background-color: #d12a4a;
                color: white;
              }
            }

            .submenu {
              left: 100%;
              min-width: 200px;
              opacity: 0;
              padding: 5px 0;
              position: absolute;
              top: -5px;
              transform: translateY(-10px);
              transition: opacity 0.3s ease, transform 0.3s ease,
                visibility 0.3s ease;
              visibility: hidden;

              &.visible {
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
              }

              a {
                color: black;
                padding: 11px 10px 10px;
                text-decoration: none;
                font-family: $madeoutersans_regular;
                font-size: pxToRem(12px);

                &:hover {
                  color: white;
                }
              }
            }
          }
        }
      }
    }
  }

  .nav-menu {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: none;
    height: min-content;

    &.open {
      display: block;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      background-color: #f73b56;
    }

    .menu-item {
      margin-bottom: 0px;
      position: relative;
      border-top: 2px solid black;
      padding: 0;
      box-sizing: border-box;

      &:last-child {
        border-bottom: 2px solid black;
      }

      > a {
        text-decoration: none;
        color: black;
        font-size: pxToRem(16px);
        font-family: $madeoutersans_regular;
        display: block;
        padding: 10px;
        text-align: left;

        &:hover {
          color: white;
        }
      }

      .submenu {
        list-style: none;
        padding: 0;
        margin-top: 10px;
        padding-left: 20px;
        display: none;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        list-style-position: inside;

        &.open {
          display: block;
          padding: 0;
          opacity: 1;
        }

        li {
          padding: 5px 0;
          border-top: 2px solid black;
          a {
            color: black;
            text-decoration: unset;
            font-family: $madeoutersans_regular;
            font-size: pxToRem(16px);

            &:hover {
              color: white;
            }
          }
        }
      }
    }
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-30%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.nav-menu {
  animation: slideDown 0.5s ease-in-out;
}
