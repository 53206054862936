@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.PrivacyPolicy {
  min-height: calc(100vh - $nav_height);
  padding: $nav_height 30px;

  @include breakpoint-for(lg) {
    padding: $nav_height 130px;
  }

  & > h2 {
    font-family: $primary_font_heavy;
    font-size: 3.3rem;
    margin: 30px 0;

    @include breakpoint-for(md) {
      font-size: 5rem;
    }

    @include breakpoint-for(lg) {
      font-size: 6rem;
    }
  }

  & > div {
    div {
      width: 100%;

      h3 {
        margin: 0;
      }

      p {
        color: $text_primary_dark_color;
        font-family: $primary_font_book;
        font-size: $base_font_size;
        letter-spacing: 0.011rem;
        line-height: 1.75rem;
        margin: 10px 0 30px;

        span {
          font-weight: 700;
        }

        @include breakpoint-for(lg) {
          text-align: justify;
        }
      }
    }
  }
}
