@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.ProtectionPolicy {
  padding: $nav_height 30px;

  @include breakpoint-for(desktop-small) {
    padding: $nav_height 130px;
  }

  & > h2 {
    font-family: $primary_font_heavy;
    font-size: 3.3rem;
    margin: 30px 0;

    @include breakpoint-for(tablet) {
      font-size: 5rem;
    }

    @include breakpoint-for(desktop-small) {
      font-size: 6rem;
    }
  }

  & > div {
    div {
      width: 100%;

      h3 {
        margin: 0;
      }

      p {
        color: $text_primary_dark_color;
        font-size: $base_font_size;
        margin: 10px 0 30px;

        span {
          font-weight: 700;
        }

        @include breakpoint-for(desktop-small) {
          text-align: justify;
        }
      }
    }
  }
}
