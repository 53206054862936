@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
@import "../../assets/scss/function";

.TrainingDetails {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  margin: 0 auto;
  overflow-x: hidden;
  padding: 90px 20px 10px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    padding: 150px 100px 40px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 150px 190px 40px;
  }

  .container-training-details {
    align-items: flex-start;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 50px;
    max-width: 1920px;
    position: relative;
    width: 100%;

    .header-details {
      display: flex;
      flex-direction: column;
      gap: 40px;
      justify-content: space-between;
      position: relative;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        flex-direction: row;
      }

      .content {
        h1 {
          font-family: $madeoutersans_bold;
          font-size: pxToRem(30px);
          margin: 0;
          text-align: left;
          line-height: 40px;

          @include breakpoint-for(desktop-small) {
            font-size: pxToRem(40px);
          }

          @include breakpoint-for(desktop-medium) {
            font-size: pxToRem(45px);
          }

          @include breakpoint-for(desktop-extra-large) {
            line-height: 60px;
            font-size: pxToRem(58px);
          }
        }

        .subtitle {
          font-family: $madeoutersans_regular;
          font-size: pxToRem(20px);
          margin: 37px 0 50px 0;
          text-align: left;

          @include breakpoint-for(desktop-small) {
            margin: 37px 0 100px 0;
          }

          @include breakpoint-for(desktop-medium) {
            font-size: pxToRem(25px);
          }

          @include breakpoint-for(desktop-extra-large) {
            font-size: pxToRem(30px);
          }
        }

        .description {
          font-family: $madeoutersans_regular;
          font-size: pxToRem(16px);
          margin: 0;
          max-width: 668px;
          position: relative;
          text-align: left;
          z-index: 2;

          @include breakpoint-for(desktop-extra-large) {
            font-size: pxToRem(18px);
          }
        }
      }

      .header-img {
        display: flex;
        justify-content: flex-start;
        width: max-content;

        @include breakpoint-for(desktop-small) {
          align-items: center;
          display: flex;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(-0%, -50%);
          z-index: 1;
        }

        img {
          object-fit: contain;
          width: 80%;

          @include breakpoint-for(desktop-small) {
            width: 391px;
            height: 664px;
          }

          @include breakpoint-for(desktop-medium) {
            width: 491px;
            height: 664px;
          }
        }
      }
    }

    .learning-section {
      background-color: transparent;
      box-sizing: border-box;
      color: #fff;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;

      @include breakpoint-for(tablet-portrait) {
        margin-top: 100px;
      }

      .text-flutuant {
        color: black;
        font-family: $display_playfair;
        font-size: pxToRem(25px);
        left: -100px;
        position: absolute;
        top: 48%;
        transform: matrix(0, -1, 1, 0, 0, 0) !important;
        visibility: hidden;

        @include breakpoint-for(tablet-portrait) {
          visibility: visible;
        }
      }

      .dot {
        align-items: center;
        background-color: black;
        border-radius: 50%;
        display: flex;
        height: 15px;
        justify-content: center;
        margin: 10px;
        width: 15px;
      }

      &__item-learning-box {
        color: #fff;
        max-width: 585px;
        padding: 0 10px;
        position: relative;

        @include breakpoint-for(tablet-portrait) {
          padding: 0;
        }
      }

      &__title {
        color: black;
        font-family: $madeoutersans_bold;
        font-size: pxToRem(16px);
        position: relative;
        text-align: left;
        text-transform: uppercase;

        @include breakpoint-for(desktop-small) {
          font-size: pxToRem(20px);
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
      }

      &__item {
        border-top: 1px solid #444;
        padding: 30px 0;
        width: 100%;

        &:nth-child(3) {
          border-bottom: 1px solid #444;
        }

        &--left {
          align-self: flex-start;
          text-align: left;

          .learning-section__item-description {
            margin-left: 40px;
            max-width: 536px;
          }

          .dot {
            position: relative;
            left: 93%;

            @include breakpoint-for(tablet-portrait) {
              left: 98%;
            }
          }
        }

        &--right {
          align-items: flex-end;
          display: flex;
          flex-direction: column;
          text-align: left;

          .learning-section__item-description {
            max-width: 485px;
            text-align: left;
          }

          .dot {
            position: relative;
            right: 93%;

            @include breakpoint-for(tablet-portrait) {
              right: 98%;
            }
          }
        }
      }

      &__item-title {
        font-family: $madeoutersans_regular;
        font-size: pxToRem(30px);
        color: black;
        font-weight: 400;
        margin: 0;

        span {
          font-family: $display_playfair;
          font-style: italic;
        }

        @include breakpoint-for(tablet-portrait) {
          font-size: pxToRem(45px);
        }
      }

      &__item-description {
        color: black;
        font-family: $madeoutersans_light;
        font-size: pxToRem(18px);
        line-height: 28px;
        margin-top: 40px;

        @include breakpoint-for(tablet-portrait) {
          font-size: pxToRem(18px);
        }
      }
    }

    .duration-section {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      margin: 90px 0;
      gap: 20px;
      flex-direction: column;

      @include breakpoint-for(desktop-small) {
        padding: 0 20px;
        flex-direction: row;
      }

      .duration {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        border-radius: 7px;
        background-color: black;
        padding: 60px 40px;
        box-sizing: border-box;
        gap: 27px;
        width: 100%;

        @include breakpoint-for(desktop-small) {
          max-width: 450px;
          width: 33%;
        }

        .duration-title {
          background-color: white;
          color: black;
          font-family: $madeoutersans_bold;
          font-size: pxToRem(28px);
          margin: 0;
          padding: 0 4px;
          text-align: left;
        }

        .duration-content {
          color: white;
          font-family: $madeoutersans_light;
          font-size: pxToRem(18px);
          font-weight: 100;
          line-height: 28px;
          margin: 0;
          text-align: left;
        }
      }
    }
  }
}

.ContactPDF {
  align-items: center;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 100px;
  overflow-x: hidden;
  padding: 40px 20px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    padding: 40px 100px 40px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 40px 190px 40px;
  }

  .container-contactpdf {
    align-items: center;
    background-color: #f7f7f7;
    box-sizing: border-box;
    display: flex;
    gap: 40px;
    max-width: 1920px;
    position: relative;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoint-for(desktop-small) {
      flex-direction: row;
    }

    .info-contact {
      display: flex;
      flex-direction: column;

      img {
        width: min-content;
        object-fit: contain;
      }

      p {
        font-family: $madeoutersans_regular;
        font-size: pxToRem(20px);
        text-align: left;
        max-width: 683px;

        @include breakpoint-for(desktop-small) {
          line-height: 39px;
          font-size: pxToRem(32px);
        }
      }
    }

    .container-contact {
      background-color: white;
      box-sizing: border-box;
      width: 100%;
      padding: 40px 20px;

      @include breakpoint-for(desktop-small) {
        padding: 70px 75px;
        width: 40%;
        min-width: 450px;
      }

      @include breakpoint-for(desktop-medium) {
        min-width: 650px;
      }

      .custom-form {
        display: flex;
        flex-direction: column;
        gap: 17px;
        min-height: 132px;
        width: 100%;

        .form-row {
          display: flex;
          flex-direction: column;
          gap: 17px;
          height: 100%;
          justify-content: space-between;
          width: 100%;

          .form-group {
            display: flex;
            flex-direction: column;
            flex: 1;
            min-width: 45%;
            position: relative;

            &.error {
              input,
              textarea {
                border-color: #e40030;
              }
            }

            input {
              border: 1px solid #000000;
              box-sizing: border-box;
              font-family: $madeoutersans_light;
              font-size: 14px;
              height: 58px;
              outline: none;
              padding: 10px;
              transition: border-color 0.3s;
              width: 100%;

              &::placeholder {
                color: #848484;
              }

              &:focus {
                border-color: #000;
              }
            }

            .error {
              bottom: -14px;
              color: red;
              font-size: 12px;
              position: absolute;
              text-align: left;
            }
          }

          textarea {
            border: 1px solid #000000;
            box-sizing: border-box;
            font-family: $madeoutersans_light;
            font-size: 14px;
            height: 132px;
            outline: none;
            padding: 10px;
            resize: none;
            transition: border-color 0.3s;
            width: 100%;

            &::placeholder {
              color: #848484;
            }

            &:focus {
              border-color: #000;
            }
          }

          .textarea-group {
            flex: 2;
            height: 100%;
            max-height: 132px;
          }

          .btn-enviar {
            align-self: flex-end;
            border: none;
            background-color: #000;
            color: #fff;
            cursor: pointer;
            flex: 1;
            font-family: $madeoutersans_bold;
            padding: 17px 66px;
            transition: background-color 0.3s;

            &:hover {
              background-color: #333;
            }
          }
        }
      }
    }
  }
}
