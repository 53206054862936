@import "../../../assets/scss/_variables";
@import "../../../assets/scss/_mixins";
@import "../../../assets/scss/function";

.NavigationLink {
  color: $text_primary_light_color;
  font-family: $madeoutersans_regular;
  font-size: pxToRem(17px);
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  color: black;

  @include breakpoint-for(desktop-small) {
    font-family: $madeoutersans_regular;
    font-size: pxToRem(18px);
  }

  &::after {
    background-color: #f73b56;
    bottom: -5px;
    content: "";
    display: block;
    height: 3px;
    position: absolute;
    right: 0;
    transition: width 0.3s ease-in-out;
    width: 0;
  }

  &:hover::after {
    width: 100%;
  }

  &.active,
  &.open {
    &::after {
      content: "";
      background-color: #f73b56;
      bottom: -5px;
      display: block;
      height: 3px;
      position: absolute;
      right: 0;
      transition: width 0.3s ease-in-out;
      width: 100%;
    }
  }

  &.editClass {
    box-shadow: none;
    font-family: $primary_font_heavy;
    margin-left: 30px;
  }
}
