@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
@import "../../assets/scss/function";

.MastertechWho {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  margin: 0 auto;
  overflow-x: hidden;
  padding: 90px 20px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    padding: 150px 100px 100px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 150px 190px 100px;
  }

  .container-mastertec-who {
    align-items: flex-start;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 1920px;
    position: relative;
    width: 100%;

    @include breakpoint-for(desktop-small) {
      margin-top: 50px;
      flex-direction: row;
      gap: 150px;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        width: 50%;
      }

      h1 {
        font-family: $madeoutersans_thin;
        font-size: pxToRem(30px);
        margin: 0;
        text-align: left;
        text-wrap: nowrap;

        @include breakpoint-for(desktop-small) {
          font-size: pxToRem(50px);
        }
      }

      h2 {
        font-family: $madeoutersans_regular;
        font-size: pxToRem(30px);
        font-weight: 400;
        line-height: 30px;
        margin: 0;
        position: relative;
        text-align: left;
        text-wrap: nowrap;
        width: max-content;

        @include breakpoint-for(desktop-small) {
          font-size: pxToRem(77px);
          line-height: 74px;
        }

        img {
          bottom: -20px;
          max-width: 100px;
          position: absolute;
          right: -6%;

          @include breakpoint-for(desktop-small) {
            bottom: -59px;
            max-width: unset;
          }
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 80px;
        margin-top: 50px;

        @include breakpoint-for(desktop-small) {
          margin-top: 100px;
        }

        p {
          font-family: $madeoutersans_regular;
          font-size: pxToRem(15px);
          line-height: 16px;
          max-width: 620px;
          text-align: left;

          @include breakpoint-for(desktop-small) {
            font-size: pxToRem(19px);
            line-height: 28px;
            margin: 0 0 0 50px;
          }

          @include breakpoint-for(desktop-extra-large) {
            margin: 0 0 0 100px;
          }
        }

        img {
          max-height: 691px;
          max-width: 492px;
          width: 100%;

          @include breakpoint-for(desktop-extra-large) {
            max-width: 692px;
          }
        }
      }
    }

    .right {
      width: 100%;

      @include breakpoint-for(desktop-small) {
        padding-top: 200px;
        width: 50%;
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 80px;

        @include breakpoint-for(desktop-small) {
          align-items: flex-end;
          margin-top: 100px;
        }

        p {
          font-family: $madeoutersans_regular;
          font-size: pxToRem(15px);
          line-height: 16px;
          max-width: 620px;
          text-align: left;
          text-align: left;

          @include breakpoint-for(desktop-small) {
            font-size: pxToRem(19px);
            line-height: 28px;
          }
        }

        img {
          max-height: 539px;
          max-width: 400px;
          width: 100%;

          @include breakpoint-for(desktop-extra-large) {
            max-width: 539px;
          }
        }

        .left-image {
          display: flex;
          justify-content: flex-start;
          width: 100%;

          img {
            max-height: 285px;
            max-width: 275px;
            width: 100%;

            @include breakpoint-for(desktop-small) {
              max-width: 375px;
            }
          }
        }
      }
    }
  }
}

.HouseKids {
  align-items: center;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 100px;
  padding: 90px 20px;
  overflow-x: hidden;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    padding: 66px 190px 90px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 66px 190px 90px;
  }

  .container-housekids {
    align-items: flex-start;
    background-color: #f7f7f7;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    position: relative;
    width: 100%;

    h4 {
      font-family: $madeoutersans_bold;
      font-size: pxToRem(20px);
      margin: 0;
    }

    .content {
      border-top: 1px solid black;
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      padding: 50px 0 0 0;
      flex-direction: column;
      gap: 50px;
      align-items: center;

      @include breakpoint-for(desktop-small) {
        flex-direction: row;
      }

      .card-housekids {
        align-items: flex-start;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        max-width: 300px;

        @include breakpoint-for(desktop-small) {
          max-width: 369px;
        }

        .header-card {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 20px;
          height: 65px;

          .title {
            font-family: $madeoutersans_regular;
            font-weight: 500;
            font-size: pxToRem(21px);
            margin: 0;
          }

          .subtitle {
            font-family: $madeoutersans_light;
            font-size: pxToRem(21px);
            margin: 0;
            text-align: left;
          }
        }

        img {
          object-fit: cover;
          width: 100%;
        }

        button {
          background-color: black;
          width: 100%;
          color: white;
          border: unset;
          font-family: $madeoutersans_bold;
          font-size: pxToRem(21px);
          display: flex;
          justify-content: space-between;
          padding: 19px 18px;
          cursor: pointer;

          .ph--arrow-right-bold {
            display: inline-block;
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cpath fill='%23fff' d='m224.49 136.49l-72 72a12 12 0 0 1-17-17L187 140H40a12 12 0 0 1 0-24h147l-51.49-51.52a12 12 0 0 1 17-17l72 72a12 12 0 0 1-.02 17.01'/%3E%3C/svg%3E");
          }

          &:hover {
            background-color: #333;
          }
        }
      }
    }
  }
}
