@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
@import "../../assets/scss/function";

.MTalks {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  margin: 0 auto;
  overflow: hidden;
  padding: 90px 20px 10px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    padding: 150px 100px 40px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 150px 190px 40px;
  }

  .container-mtalks {
    align-items: flex-start;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 50px;
    max-width: 1920px;
    position: relative;
    width: 100%;

    .header-mtalks {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;

      @include breakpoint-for(tablet) {
        height: 180px;
      }

      h1 {
        font-family: $madeoutersans_thin;
        font-size: pxToRem(45px);
        font-weight: 300;
        margin: 0;
        text-align: left;

        @include breakpoint-for(desktop-medium) {
          font-size: pxToRem(49px);
        }
      }

      h2 {
        font-family: $madeoutersans_regular;
        font-size: pxToRem(30px);
        font-weight: 300;
        line-height: 30px;
        margin: 0;
        position: relative;
        text-align: left;

        @include breakpoint-for(desktop-small) {
          line-height: 65px;
          font-size: pxToRem(68px);
        }

        span {
          font-family: $madeoutersans_black;
        }
      }

      .circular-text-container {
        align-items: center;
        display: flex;
        height: 150px;
        justify-content: center;
        margin-top: 160px;
        position: relative;
        width: 150px;
        display: none;

        @include breakpoint-for(tablet) {
          display: flex;
          margin-top: 120px;
        }

        .circle-svg {
          animation: rotate-text 10s linear infinite;
          height: 100%;
          transform-origin: center;
          width: 100%;
        }

        .circle-text {
          fill: black;
          font-family: $madeoutersans_regular;
          font-size: pxToRem(11px);
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        .arrow {
          position: absolute;
          width: 30px;
        }

        @keyframes rotate-text {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }

    .section-mtalks {
      display: flex;
      flex-direction: column;
      gap: 50px;
      margin: 50px 0;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        flex-direction: row;
        gap: 150px;
        margin: 120px 0 0 0;
      }

      .left {
        align-items: flex-end;
        display: flex;
        flex-direction: column-reverse;
        width: 100%;

        @include breakpoint-for(desktop-small) {
          flex-direction: column;
          width: 50%;
        }

        p {
          font-family: $madeoutersans_regular;
          font-size: pxToRem(18px);
          margin-bottom: 30px;
          max-width: 620px;
          text-align: left;

          @include breakpoint-for(desktop-small) {
            padding-left: 80px;
            margin: 0 0 130px 0;
          }
        }

        img {
          max-height: 691px;
          object-fit: cover;
          width: 100%;
        }

        span {
          font-family: $madeoutersans_medium;
          font-size: pxToRem(30px);
          margin: 80px 0;
          max-width: 620px;
          text-align: left;
        }
      }

      .right {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        width: 100%;

        @include breakpoint-for(desktop-small) {
          width: 50%;
        }

        img {
          max-height: 448px;
          object-fit: cover;
          width: 100%;
        }

        p {
          font-family: $madeoutersans_regular;
          font-size: pxToRem(18px);
          margin: 50px 0;
          max-width: 620px;
          text-align: left;

          @include breakpoint-for(desktop-small) {
            margin: 120px 0;
          }
        }

        span {
          font-family: $madeoutersans_medium;
          font-size: pxToRem(30px);
          margin: 80px 0;
          max-width: 620px;
          text-align: left;
        }
      }
    }

    .talks-list {
      display: flex;
      flex-direction: column;
      margin: 0;
      width: 100%;

      .talk {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 85px 0;
        box-sizing: border-box;
        border-top: 1px solid #00000084;
        position: relative;

        // &:last-child {
        //   border-bottom: 1px solid #00000084;
        // }

        .title {
          font-family: $madeoutersans_thin;
          font-size: pxToRem(30px);
          margin: 0;
          text-align: left;

          @include breakpoint-for(desktop-small) {
            font-size: pxToRem(35px);
          }
        }

        .subtitle {
          font-family: $madeoutersans_medium;
          font-size: pxToRem(20px);
          margin: 0;
          max-height: 150px;
          text-align: left;

          @include breakpoint-for(desktop-small) {
            font-size: pxToRem(25px);
          }
        }

        .description {
          font-family: $madeoutersans_light;
          font-size: pxToRem(18px);
          line-height: 28px;
          font-weight: 300;
          margin: 19px 0 0 0;
          text-align: left;
          padding-left: 40px;

          @include breakpoint-for(desktop-small) {
            font-size: pxToRem(18px);
          }
        }

        .dot {
          background-color: black;
          border-radius: 50%;
          height: 14px;
          width: 14px;
          position: absolute;
          bottom: 40px;

          &.right {
            left: 99%;
          }

          &.left {
            right: 99%;
          }
        }
      }
    }
  }
}
