@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
@import "../../assets/scss/function";

.BuildingList {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  margin: 0 auto;
  overflow-x: hidden;
  padding: 90px 20px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    padding: 150px 100px 100px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 150px 190px 100px;
  }

  .container-buildinglist {
    align-items: flex-start;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 50px;
    max-width: 1920px;
    position: relative;
    width: 100%;

    .header-buildinglist {
      width: 100%;

      h1 {
        font-family: $madeoutersans_thin;
        font-size: pxToRem(20px);
        font-weight: 200;
        margin: 0;
        text-align: left;

        @include breakpoint-for(tablet) {
          font-size: pxToRem(40px);
        }

        @include breakpoint-for(desktop-extra-large) {
          font-size: pxToRem(50px);
        }
      }

      h2 {
        font-family: $madeoutersans_bold;
        font-size: pxToRem(40px);
        margin: 0;
        text-align: left;
        position: relative;

        @include breakpoint-for(tablet) {
          font-size: pxToRem(70px);
          width: max-content;
        }

        @include breakpoint-for(desktop-medium) {
          font-size: pxToRem(80px);
        }

        @include breakpoint-for(desktop-extra-large) {
          font-size: pxToRem(86px);
        }

        img {
          bottom: -15px;
          max-width: 200px;
          position: absolute;
          right: 25%;

          @include breakpoint-for(desktop-small) {
            bottom: -30px;
            right: -10%;
            max-width: unset;
          }
        }
      }

      .content {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        width: 100%;

        @include breakpoint-for(tablet) {
          margin-top: 106px;
        }

        .info-text {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 100%;

          p {
            font-family: $madeoutersans_regular;
            font-size: pxToRem(15px);
            line-height: 28px;
            margin: 0;
            max-width: 620px;
            text-align: left;

            @include breakpoint-for(desktop-medium) {
              font-size: pxToRem(18px);
            }
          }
        }

        .circular-text-container {
          align-items: center;
          display: flex;
          height: 150px;
          justify-content: center;
          margin-top: 30px;
          position: relative;
          width: 150px;

          @include breakpoint-for(tablet) {
            margin-top: 50px;
          }

          .circle-svg {
            animation: rotate-text 10s linear infinite;
            height: 100%;
            transform-origin: center;
            width: 100%;
          }

          .circle-text {
            fill: black;
            font-family: $madeoutersans_regular;
            font-size: pxToRem(11px);
            letter-spacing: 1px;
            text-transform: uppercase;
          }

          .arrow {
            position: absolute;
            width: 30px;
          }

          @keyframes rotate-text {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
    }

    .list-buildinglist {
      display: flex;
      flex-direction: column;
      width: 100%;
      list-style: none;
      padding: 0;

      .trail {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid black;
        padding: 55px 0;
        flex-direction: column-reverse;
        gap: 30px;

        @include breakpoint-for(tablet) {
          gap: unset;
          flex-direction: row;
        }

        &:first-child {
          border-top: 1px solid black;
        }

        &:last-child {
          border-bottom: unset;
        }

        .infos-buildinglist {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 63px;

          div {
            .title {
              font-family: $madeoutersans_bold;
              font-size: pxToRem(30px);
              margin: 0 0 35px 0;
              text-align: left;

              @include breakpoint-for(desktop-medium) {
                font-size: pxToRem(35px);
              }
            }

            .description {
              font-family: $madeoutersans_regular;
              font-size: pxToRem(15px);
              margin: 0;
              text-align: left;
              max-height: 150px;

              @include breakpoint-for(desktop-medium) {
                font-size: pxToRem(18px);
              }
            }
          }

          button {
            align-items: center;
            background-color: transparent;
            border-radius: 27px;
            border: 2px solid #000000;
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            font-family: $madeoutersans_regular;
            font-size: pxToRem(17px);
            gap: 15px;
            justify-content: space-between;
            max-width: 202px;
            padding: 5px 20px;
            transition: all 0.3s;
            width: max-content;

            @include breakpoint-for(desktop-medium) {
              font-size: pxToRem(20px);
            }
            svg {
              path {
                fill: black;
                transition: all 0.3s;
              }
            }

            &:hover {
              background-color: black;
              border-color: black;
              color: white;

              svg {
                path {
                  fill: white;
                }
              }
            }
          }
        }

        .container-img {
          display: flex;
          justify-content: flex-end;
          width: 100%;

          @include breakpoint-for(tablet) {
            height: 100%;
            width: max-content;
          }

          img {
            width: 100%;
            object-fit: contain;
            max-width: 447px;
          }
        }
      }
    }
  }
}
