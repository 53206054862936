@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.contact-form {
  .container-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
  }
  .form-group {
    margin-bottom: 1rem;
    width: 100%;
    position: relative;
    flex-direction: column;

    input,
    textarea {
      width: 100%;
      font-family: $roboto_regular;
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 4px;

      &.error {
        border-color: red !important;
      }

      &::placeholder {
        font-family: $roboto_regular;
      }
    }

    .error-message {
      color: red;
      font-size: 0.75rem;
      margin-top: 0.25rem;
    }
  }

  .feedback-message {
    margin-top: 20px !important;
    font-size: 20px !important;
    font-family: $roboto_bold;
    max-width: 400px !important;
  }
}
