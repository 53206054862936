@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";

.Question {
  width: 100%;

  .Select {
    width: 100%;
  }

  .Input {
    color: black;

    label {
      color: black;
    }

    input {
      border: 1px solid black;
      color: black;
    }
  }

  .input-box {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    label {
      font-family: $primary_font_book;
      font-size: 18px;
      margin: 9px 0;
    }

    input {
      border: 1px solid #707070;
      font-family: $primary_font_book;
      font-size: 20px;
      height: 60px;
      padding: 14px 10px;
      width: 100%;

      &.error {
        border: 1px solid $error;
        outline: none;
      }
    }

    p {
      color: $error;
      font-family: $primary_font_book;
      margin: 5px 0 0 0;
    }

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      background: transparent;
      background: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') 97% 50% no-repeat transparent;
      border: 1px solid #707070;
      font-family: $primary_font_book;
      font-size: 26px;
      height: 60px;
      padding: 14px 20px;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        width: 65%;
      }
    }
  }
}
