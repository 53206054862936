@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.B2CCourse {
  padding-top: $nav_height_mobile;

  @include breakpoint-for(tablet) {
    padding-top: $nav_height;
  }

  .course-info {
    background-color: $background_primary_dark_color;
    display: grid;
    grid-template-areas: 'format'
      'title'
      'description'
      'info';
    grid-template-columns: 1fr;
    justify-content: flex-start;

    @include breakpoint-for(desktop-small) {
      align-items: flex-start;
      background-color: $background_primary_light_color;
      grid-template-areas:
        'back .'
        'format .'
        'title description'
        'info description';
      grid-template-columns: 1fr 1fr;
      filter: unset;
    }

    @include breakpoint-for(desktop-large) {
      flex-direction: row;
      padding: 24px 0 128px $base_padding_desktop_large;
    }

    &>svg {
      display: none;
      grid-area: back;
      position: absolute;
      visibility: hidden;
      width: 40px;
      z-index: 0;

      @include breakpoint-for(desktop-large) {
        padding: 24px 0 128px $base_padding_desktop_large;
      }

      @include breakpoint-for (desktop-small) {
        display: block;
        left: $base_padding;
        visibility: visible;
        width: 60px;
      }

      &.RotatingText {
        aspect-ratio: 1 / 1;
        height: 200px;
        left: 200px;
        top: 620px;
        width: unset;

        @include breakpoint-for(tablet) {
          height: 300px;
          left: 600px;
          top: 520px;
        }

        @include breakpoint-for(desktop-small) {
          height: 320px;
          left: 170px;
          top: 740px;
        }

        @include breakpoint-for(desktop-small) {
          left: 320px;
          top: 600px;
        }

        @include breakpoint-for(desktop-large) {
          height: 360px;
          left: 350px;
          top: 850px;
        }
      }
    }

    h2 {
      background-color: $background_primary_light_color;
      filter: invert(1);
      font-family: $primary_font_heavy;
      font-size: 2rem;
      grid-area: title;
      line-height: 45px;
      margin: 15px 0 41px;
      padding: 0 20px;
      text-align: left;
      width: 80%;

      @include breakpoint-for(mobile-large) {
        font-size: 2.2rem;
      }

      @include breakpoint-for (desktop-small) {
        padding: 0 $base_padding;
        font-size: $title_font_size;
      }

      @include breakpoint-for (tablet) {
        font-size: $xl_title_font_size;
        line-height: 45px;
        margin: 15px 0 61px;
      }

      @include breakpoint-for (desktop-small) {
        filter: unset;
      }

      @include breakpoint-for (desktop-large) {
        font-size: $xxxl_title_font_size;
        line-height: 60px;
      }
    }

    .format {
      background-color: $background_primary_light_color;
      filter: invert(1);
      font-family: $primary_font_book;
      font-size: $lg_base_font_size;
      grid-area: format;
      margin: 68px 0 0;
      padding: 0 20px;
      text-align: left;
      width: 80%;

      @include breakpoint-for (desktop-small) {
        padding: 0 $base_padding;
      }

      @include breakpoint-for(tablet) {
        font-size: $xxxl_base_font_size;
      }

      @include breakpoint-for(desktop-small) {
        filter: unset;
        font-family: $primary_font_medium;
        margin: 78px 0 0;
      }

      @include breakpoint-for(desktop-large) {
        margin: 128px 0 0;
      }
    }

    .course-info-details {
      background-color: $background_primary_light_color;
      grid-area: info;
      margin: 0;
      max-width: 100vw;
      padding: 0 20px;
      width: 100%;

      @include breakpoint-for (desktop-small) {
        padding: 0 $base_padding;
      }

      h3 {
        font-family: $primary_font_heavy;
        font-size: $base_font_size;
        margin: 0;
        text-align: left;

        &:first-of-type {
          margin-top: 48px;

          @include breakpoint-for(desktop-small) {
            margin-top: 0;
          }
        }
      }

      p {
        font-family: $primary_font_book;
        font-size: $xxs_base_font_size;
        margin: 0 0 30px;
        text-align: left;

        @include breakpoint-for(tablet) {
          margin: 0 0 53px;
        }
      }

      .product-details {
        align-items: center;
        border-bottom: 0;
        border-radius: 15px;
        border: 1px solid #707070;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 25px 0;
        width: 390px;
        z-index: 3;

        .title-product {
          align-items: center;
          background-color: $text_primary_dark_color;
          border-radius: 13px 13px 0 0;
          display: flex;
          justify-content: space-between;
          min-height: 60px;
          padding: 10px 25px;
          width: 100%;


          div {
            display: flex;

            .car-icon {
              width: 26px;
              height: 20px;
            }

            p {
              margin-left: 13px;
            }
          }

          p {
            color: white;
            font-family: $primary_font_book;
            font-size: 17px;
            margin: 0;
            text-align: left;

            span {
              font-size: 16px;
              text-transform: uppercase;
            }
          }
        }

        .sub-details-product {
          background-color: white;
          display: flex;
          justify-content: space-between;
          padding: 30px 25px;
          width: 100%;

          .infos-product {
            p {
              color: grey;
              font-family: $primary_font_book;
              font-size: 16px;
              margin: 7px 0;
              text-align: left;

              &.green {
                color: $text_green_color;
              }

              &:last-child {
                font-style: italic;
              }

              strong {
                color: $text_primary_dark_color;
                font-family: $primary_font_book;
                margin: 0;
              }
            }
          }

          .increment-product {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .contain-product {
              align-items: center;
              display: flex;
              justify-content: space-between;

              p {
                font-family: $primary_font_demi;
                font-size: 16px;
                margin: 0 10px;
                text-transform: uppercase;
              }
            }

            button {
              background: none;
              border: none;
              color: inherit;
              cursor: pointer;
              font: inherit;
              outline: inherit;
              padding: 0;

              img {
                height: 19px;
                width: 19px;
              }
            }
          }
        }

        .purchase-course-button {
          background-color: $text_green_color;
          border: none;
          color: $text_primary_light_color;
          cursor: pointer;
          font-family: $primary_font_demi;
          font-size: 23px;
          height: 68px;
          width: 100.7%;
        }
      }
    }

    .course-info-description {
      align-items: center;
      background-color: $background_primary_light_color;
      display: flex;
      filter: invert(1);
      grid-area: description;
      height: fit-content;
      justify-content: space-between;
      margin: 20px 0 0;
      padding: 0 20px 68px;
      position: relative;
      width: 80%;
      flex-direction: column;

      @include breakpoint-for (desktop-small) {
        padding: 0 $base_padding 178px;
      }

      @include breakpoint-for (desktop-small) {
        filter: unset;
        padding: 0 0 0 205px;
      }

      @include breakpoint-for (desktop-large) {
        padding: 0 0 0 285px;
      }

      h2 {
        font-family: $primary_font_heavy;
        font-size: $lg_title_font_size;
        margin: 0;
        text-align: left;

        @include breakpoint-for (desktop-small) {
          font-size: $xl_title_font_size;
        }

        @include breakpoint-for (desktop-large) {
          font-size: $xxxl_title_font_size;
        }
      }

      .description-course {
        background-color: white;
        font-family: $primary_font_medium;
        max-width: 390px;
        text-align: left;
        z-index: 1;
      }

      .product-details {
        align-items: center;
        border-radius: 19px;
        border: 1px solid #707070;
        border-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 25px 0;
        width: 390px;
        z-index: 3;

        .title-product {
          align-items: center;
          background-color: $text_primary_dark_color;
          border-radius: 19px 19px 0 0;
          display: flex;
          justify-content: space-between;
          min-height: 60px;
          padding: 10px 25px;
          width: 100%;


          div {
            display: flex;

            .car-icon {
              width: 26px;
              height: 20px;
            }

            p {
              margin-left: 13px;
            }
          }

          p {
            color: white;
            font-family: $primary_font_book;
            font-size: 17px;
            margin: 0;
            text-align: left;

            span {
              font-size: 16px;
              text-transform: uppercase;
            }
          }
        }

        .sub-details-product {
          background-color: white;
          display: flex;
          justify-content: space-between;
          padding: 30px 25px;
          width: 100%;

          .infos-product {
            p {
              color: grey;
              font-family: $primary_font_book;
              font-size: 16px;
              margin: 7px 0;
              text-align: left;

              &.green {
                color: $text_green_color;
              }

              &:last-child {
                font-style: italic;
              }

              strong {
                color: $text_primary_dark_color;
                font-family: $primary_font_book;
                margin: 0;
              }
            }
          }

          .increment-product {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .contain-product {
              align-items: center;
              display: flex;
              justify-content: space-between;

              p {
                font-family: $primary_font_demi;
                font-size: 16px;
                margin: 0 10px;
                text-transform: uppercase;
              }
            }

            button {
              background: none;
              border: none;
              color: inherit;
              cursor: pointer;
              font: inherit;
              outline: inherit;
              padding: 0;

              img {
                height: 19px;
                width: 19px;
              }
            }
          }
        }

        .purchase-course-button {
          background-color: $text_green_color;
          border: none;
          color: $text_primary_light_color;
          cursor: pointer;
          font-family: $primary_font_demi;
          font-size: 23px;
          height: 68px;
          width: 100.7%;
        }
      }
    }

    .course-image {
      left: 180px;
      position: absolute;
      visibility: hidden;

      @include breakpoint-for(tablet) {
        display: inline;
      }

      @include breakpoint-for(desktop-small) {
        left: -60px;
        top: -140px;
        visibility: visible;
      }

      @include breakpoint-for(desktop-medium) {
        height: 600px;
      }

      @include breakpoint-for(desktop-large) {
        height: 700px;
      }
    }
  }

  .course-details {
    padding: 51px 20px 0;

    @include breakpoint-for (desktop-small) {
      padding: 51px 20px 0;
    }

    h3 {
      font-family: $primary_font_heavy;
      font-size: $title_font_size;
      margin: 0;
      text-align: left;

      @include breakpoint-for (desktop-small) {
        font-size: $xxl_title_font_size;
        padding: 0 0 0 $base_padding_desktop_large;
      }

      @include breakpoint-for (desktop-large) {
        margin: 128px 0 62px;
      }
    }

    .modules {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 51px 0 0;
      width: 100%;

      @include breakpoint-for (desktop-small) {
        padding: 51px 123px 0 143px;
        width: 100%;
      }

      @include breakpoint-for (desktop-large) {
        padding: 91px 223px 0 243px;
      }

      details {
        align-items: center;
        animation-direction: reverse;
        animation-name: accordion-close;
        animation-duration: 2s;
        animation-timing-function: ease-in-out;
        border-bottom: 1px solid $background_primary_dark_color;
        display: flex;
        font-family: $primary_font_book;
        font-size: $xl_base_font_size;
        max-height: 177px;
        justify-content: space-between;
        overflow: hidden;
        padding: 35px 15px;
        text-align: left;
        transform-origin: top;
        transition: 1s;
        width: 100%;
        $accordion-max-height: 500px;

        @include breakpoint-for (desktop-small) {
          font-size: $xxxl_base_font_size;
          padding: 31px 25px;
        }

        @include breakpoint-for (desktop-large) {
          font-size: $xxxxs_title_font_size;
          padding: 51px 30px;
        }

        @keyframes accordion-close {
          @for $i from 1 to 11 {
            #{$i * 10}#{"%"} {
              max-height: ($accordion-max-height * $i * 10) / 100;
            }
          }
        }

        &:first-child {
          border-top: 1px solid $background_primary_dark_color;
        }

        &[open] {
          max-height: $accordion-max-height;

          summary::after {
            content: '-';
            font-size: $xxs_title_font_size;

            @include breakpoint-for (desktop-small) {
              font-size: $title_font_size;
            }

            @include breakpoint-for (desktop-large) {
              font-size: $lg_title_font_size;
            }
          }
        }

        strong {
          font-family: $primary_font_heavy;
        }

        summary {
          align-items: center;
          appearance: none;
          display: flex;
          justify-content: space-between;
          -moz-appearance: none;
          -webkit-appearance: none;

          &::after {
            align-items: center;
            aspect-ratio: 1 / 1;
            background-color: $background_primary_dark_color;
            border-radius: 50%;
            box-sizing: border-box;
            color: $text_primary_light_color;
            content: '+';
            display: flex;
            font-family: $primary_font_light;
            font-size: $xxs_title_font_size;
            font-weight: lighter;
            height: 45px;
            justify-content: center;
            line-height: 10px;
            padding-bottom: 3px;

            @include breakpoint-for (desktop-small) {
              font-size: $title_font_size;
            }

            @include breakpoint-for (desktop-large) {
              font-size: $lg_title_font_size;
            }
          }

          p {
            margin: 0;
          }
        }
      }
    }
  }

  .contact {
    text-align: left;
    padding: 0 20px 68px;

    @include breakpoint-for (desktop-small) {
      padding: 0 20px 178px;
    }

    h3 {
      font-family: $primary_font_heavy;
      font-size: $title_font_size;
      margin: 128px 0 62px;
      text-align: left;

      @include breakpoint-for (desktop-small) {
        font-size: $xxl_title_font_size;
        padding: 0 0 0 $base_padding_desktop_large;
      }

      @include breakpoint-for (desktop-large) {
        margin: 128px 0 62px;
      }
    }

    a {
      align-items: center;
      background-color: $background_primary_light_color;
      border: 1px solid $background_primary_dark_color;
      color: $background_primary_dark_color;
      cursor: pointer;
      display: inline-flex;
      font-family: $primary_font_book;
      font-size: $base_font_size;
      justify-content: space-between;
      margin: 0 20px 20px 0;
      padding: 10px 22px;
      text-decoration: none;
      width: 100%;

      @include breakpoint-for (tablet) {
        width: inherit;
      }

      @include breakpoint-for (desktop-small) {
        margin: 0 26px 0 0;
      }

      &:nth-child(2) {
        background-color: $background_primary_dark_color;
        color: $text_primary_light_color;

        @include breakpoint-for (desktop-small) {
          margin-right: 26px;
          margin-left: $base_padding_desktop_large;
        }
      }

      img {
        height: 35px;
        margin-left: 10px;
      }
    }
  }

  .Button.subscription {
    background-color: $background_primary_light_color;
    color: $text_primary_dark_color;
    font-family: $primary_font_heavy;
    font-size: $xl_base_font_size;
    justify-content: space-between;
    margin-top: 40px;
    width: 100%;
    z-index: 1;

    @include breakpoint-for (tablet) {
      width: 300px;
    }

    @include breakpoint-for (desktop-small) {
      background-color: $background_primary_dark_color;
      color: $text_primary_light_color;
      font-size: $xxl_base_font_size;
    }

    @include breakpoint-for (desktop-large) {
      margin-top: 100px;
      width: 376px;
    }

    svg {
      display: inline;
      position: unset;
      transform: rotate(180deg);
      width: 32px;

      @include breakpoint-for (desktop-small) {
        filter: invert(1);
      }
    }
  }
}