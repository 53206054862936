@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.B2BCourseCard {
  background-color: $background_primary_dark_color;
  color: $text_primary_light_color;
  display: grid;
  font-family: $primary_font_book;
  grid-template-areas: 'category image'
  'title image'
  'description description'
  'button .';
  grid-template-columns: minmax(0, 7fr) minmax(0, 3fr);
  grid-template-rows: 1.5fr 5fr 3fr 2fr;
  height: 301px;
  padding: 20px;
  
  @include breakpoint-for(tablet) {
    aspect-ratio: 1.05 / 1;
    grid-template-columns: 6fr 4fr;
    padding: 25px 24px 25px 40px;  
    width: calc(50% - 8px);
   }

   @include breakpoint-for(desktop-small) {
    grid-template-columns: 7fr 3fr;
    grid-template-rows: 1fr 5.5fr 4.5fr minmax(54px, 1.5fr);
    height: 375px;
    padding: 32px 30px 32px 30px;  
    width: calc(33.33% - 8px);
   }

   @include breakpoint-for(desktop-medium) {
    padding: 42px 47px 42px 47px;  
   }

   @include breakpoint-for(desktop-large) {
    height: 605px; 
    padding: 62px 71px 62px 70px;
    max-width: 632px;
   } 

   &:hover {

    @include breakpoint-for(desktop-small) {
      button {
        display: inline-flex;
        visibility: visible;
      }
    }
   }

  h2 {
    font-family: $primary_font_heavy;
    font-size: $xxxxs_title_font_size;
    grid-area: title;
    margin: 0;
    text-align: left;
    text-transform: uppercase;
  
    @include breakpoint-for(desktop-small) {
      font-size: $xxxs_title_font_size;
    }

    @include breakpoint-for(desktop-medium) {
      font-size: $xxs_title_font_size;
    }

    @include breakpoint-for(desktop-large) {
      font-size: $xs_title_font_size;
    } 
  }

  img {
    aspect-ratio: 1 / 1;
    grid-area: image;
    justify-self: flex-end;
    width: 100px;

    @include breakpoint-for(tablet) {
      width: 93px; 
     }
  
     @include breakpoint-for(desktop-small) {
      width: 100px;
     }
  
     @include breakpoint-for(desktop-medium) {
      width: 119px;
     }

     @include breakpoint-for(desktop-large) {
      width: 180px;
     } 
  }

  p {
    color: $text_secondary_light_color;
    font-family: $primary_font_light;
    margin: 0;
    text-align: left;

    &:first-child {
      font-size: $xs_base_font_size;
      grid-area: category;
  
      @include breakpoint-for(desktop-small) {
      font-size: $base_font_size;
      }
    }

    &:last-child {
      font-size: $xxxs_base_font_size;
      grid-area: description;

      @include breakpoint-for(desktop-small) {
        font-size: $xs_base_font_size;
        max-width: 415px;
      }
    }
  }

  button {
    align-items: center;
    background: linear-gradient(to left, $background_primary_dark_color 50%, $background_primary_light_color 50%) right;
    background-size: 200% 100%;
    border-color: $background_primary_light_color;
    color: $text_primary_light_color;
    cursor: pointer;
    display: flex;
    font-family: $primary_font_heavy;
    font-size: $xl_base_font_size;
    grid-area: button;
    height: 54px;
    justify-content: space-between;
    padding: 20px;
    transition:all 2s ease;
    width: 221px;
    
    @include breakpoint-for(desktop-small) {
      display: none;
      visibility: hidden;

      &:hover {
        background-position: left;
        color: $text_primary_dark_color;
        transition: all 1s ease;
  
        svg {
          filter: unset;
          transition: 1.5s;
        }
      }
    }

    svg {
      filter: invert(1);
      transform: rotate(180deg);
      width: 26px;
    }
  }
}
