@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
@import "../../assets/scss/function";

.Consultancy {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  margin: 0 auto;
  overflow: hidden;
  padding: 90px 20px 10px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    padding: 150px 100px 40px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 150px 190px 40px;
  }

  .container-consultancy {
    align-items: flex-start;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 50px;
    max-width: 1920px;
    position: relative;
    width: 100%;

    h1 {
      text-align: left;
      font-family: $madeoutersans_regular;
      font-size: pxToRem(20px);
      font-weight: 500;
      margin: 0;

      @include breakpoint-for(tablet) {
        line-height: 53px;
        font-size: pxToRem(53px);
      }

      span {
        font-family: $madeoutersans_bold;
      }
    }

    .header-consultancy {
      align-items: flex-start;
      box-sizing: border-box;
      display: flex;
      flex-direction: column-reverse;
      gap: 25px;
      justify-content: space-between;
      margin-top: 20px;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        margin-top: 100px;
        flex-direction: row;
        padding-left: 94px;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        p {
          font-family: $madeoutersans_regular;
          font-size: pxToRem(15px);
          line-height: 28px;
          margin: 0;
          max-width: 630px;
          text-align: left;

          @include breakpoint-for(desktop-large) {
            font-size: pxToRem(18px);
          }
        }
      }

      img {
        width: 100%;

        @include breakpoint-for(desktop-medium) {
          max-height: 347px;
          max-width: 601px;
          width: unset;
        }
      }
    }

    .section-consultancy {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 100%;
      margin-top: 100px;
      box-sizing: border-box;

      @include breakpoint-for(desktop-small) {
        padding-left: 94px;
      }

      .steps-texts {
        display: flex;
        gap: 20px;
        width: 100%;
        flex-direction: column;

        @include breakpoint-for(desktop-small) {
          flex-direction: row;
        }

        .step-text {
          display: flex;
          flex-direction: column;
          gap: 20px;
          background-color: #f7f7f7;
          width: 100%;
          padding: 127px 30px 38px;
          border-radius: 7px;

          @include breakpoint-for(desktop-large) {
            min-width: 406px;
          }

          p {
            font-family: $madeoutersans_bold;
            font-size: pxToRem(20px);
            line-height: 28px;
            margin: 0;
            text-align: left;

            @include breakpoint-for(desktop-small) {
              font-size: pxToRem(20px);
            }

            @include breakpoint-for(desktop-large) {
              font-size: pxToRem(26px);
            }

            span {
              color: #f7f7f7;
              background-color: #e40030;
            }
          }
        }
      }

      .steps-images {
        display: flex;
        gap: 20px;
        width: 100%;
        flex-direction: column;

        @include breakpoint-for(desktop-small) {
          flex-direction: row;
        }

        .step-image {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          width: 100%;
          border-radius: 7px;

          @include breakpoint-for(desktop-large) {
            min-width: 406px;
          }

          img {
            object-fit: contain;

            @include breakpoint-for(desktop-large) {
              width: 278px;
              height: 297px;
            }
          }

          p {
            font-family: $madeoutersans_regular;
            font-size: pxToRem(16px);
            line-height: 28px;
            margin: 0;
            text-align: left;
            width: 278px;

            @include breakpoint-for(desktop-large) {
              font-size: pxToRem(18px);
            }
          }
        }
      }
    }

    .cases-options {
      max-width: 1920px;
      width: 100%;
      margin: 50px 0;

      @include breakpoint-for(desktop-small) {
        margin: 100px 0;
      }

      .title-like {
        border-bottom: 2px solid #00000084;
        font-family: $madeoutersans_bold;
        font-size: pxToRem(20px);
        padding-bottom: 8px;
        text-align: left;
        text-transform: uppercase;
      }

      .cards {
        display: flex;
        flex-direction: column;
        gap: 26px;
        margin-top: 26px;

        @include breakpoint-for(desktop-small) {
          flex-direction: row;
          height: 651px;
        }

        .card {
          cursor: pointer;
          position: relative;
          width: 100%;

          @include breakpoint-for(desktop-small) {
            width: 50%;
          }

          .content-card {
            background-color: rgba(0, 0, 0, 0.6);
            bottom: 4px;
            color: #fff;
            display: flex;
            flex-direction: column;
            height: max-content;
            left: 0;
            padding: 30px 20px;
            position: absolute;
            right: 0;
            transition: opacity 0.3s ease, visibility 0.3s ease;
            z-index: 2;

            @include breakpoint-for(desktop-small) {
              bottom: 0;
            }

            p {
              font-family: $madeoutersans_light;
              font-size: pxToRem(13px);
              margin: 0;
              text-align: left;
              width: 100%;

              &.title {
                font-family: $madeoutersans_medium;
                font-size: pxToRem(14px);
                margin-bottom: 0.5rem;
                text-transform: uppercase;
              }

              @include breakpoint-for(desktop-small) {
                font-size: pxToRem(16px);

                &.title {
                  font-size: pxToRem(18px);
                }
              }
            }
          }

          img {
            aspect-ratio: 1 / 1;
            height: 350px;
            object-fit: cover;
            width: 100%;

            @include breakpoint-for(desktop-small) {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
