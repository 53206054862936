@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.SetList {
  align-items: center;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 120px 20px;
  background-image: url("../../../assets/images/setlist/bg.png");
  background-position: center 4%;

  @include breakpoint-for(desktop-small) {
    padding: 150px 100px 100px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 150px 190px 100px;
  }

  .container-setlist {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    width: 100%;

    h2 {
      color: white;
      font-family: $madeoutersans_regular;
      font-size: pxToRem(30px);
      margin: 0 0 80px 0;

      @include breakpoint-for(tablet) {
        font-size: pxToRem(44px);
      }
    }

    .button-list {
      background-color: #000;
      padding: 20px 0;
      width: 100%;

      .button-item {
        align-items: flex-start;
        color: #fff;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        text-transform: uppercase;
        width: 100%;

        @include breakpoint-for(tablet) {
          align-items: flex-end;
        }

        &:hover {
          color: #e40030;

          .dots {
            &::before {
              background: repeating-linear-gradient(
                to right,
                #e40030 0,
                #e40030 2px,
                transparent 2px,
                transparent 6px
              );
            }
          }
        }

        .title {
          flex-shrink: 0;
          font-family: $madeoutersans_bold;
          font-size: pxToRem(20px);
          white-space: nowrap;
          margin-right: 0;

          @include breakpoint-for(tablet) {
            // margin-right: 10px;
            font-size: pxToRem(25px);
          }
        }

        .dots {
          border-radius: 50%;
          display: flex;
          flex-grow: 1;
          margin-bottom: 5px;
          // margin: 0 10px;

          &::before {
            content: "";
            display: block;
            height: 3px;
            width: 100%;
            background: repeating-linear-gradient(
              to right,
              #fff 0,
              #fff 2px,
              transparent 2px,
              transparent 6px
            );
          }
        }

        .description {
          flex-shrink: 0;
          font-family: $madeoutersans_bold;
          font-size: pxToRem(15px);
          margin-left: 0;
          text-align: left;
          text-transform: lowercase;

          @include breakpoint-for(tablet) {
            white-space: nowrap;
            // margin-left: 10px;
            font-size: pxToRem(18px);
          }
        }
      }

      @media (max-width: 767px) {
        .button-item {
          flex-direction: column;
          text-align: center;
        }

        .title,
        .description {
          text-align: center;
          margin-right: 0;
        }
      }
    }
  }
}
