@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.banner-container {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px auto 0;
  min-height: 100vh;
  padding: 120px 20px;

  @include breakpoint-for(desktop-small) {
    padding: 150px 100px 100px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 150px 190px 100px;
  }

  .Banner {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    width: 100%;
    z-index: 2;
    max-width: 1920px;

    .initial-banner {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
    }

    .text-container {
      align-items: center;
      display: flex;
      justify-content: center;
      position: relative;

      img {
        max-width: 503px;
        position: absolute;
        top: 25%;
        width: 100%;

        @include breakpoint-for(tablet) {
          top: 35%;
        }

        @include breakpoint-for(desktop-small) {
          top: 30%;
          max-width: 750px;
        }

        @include breakpoint-for(desktop-medium) {
          top: 35%;
        }

        @include breakpoint-for(desktop-large) {
          max-width: 903px;
        }
      }

      h1 {
        font-family: $madeoutersans_bold;
        font-size: pxToRem(35px);
        color: black;
        text-align: center;
        margin: 0;
        padding: 0;

        @include breakpoint-for(tablet) {
          font-size: pxToRem(50px);
        }

        @include breakpoint-for(desktop-small) {
          line-height: 75px;
          font-size: pxToRem(100px);
        }

        @include breakpoint-for(desktop-medium) {
          line-height: 102px;
          font-size: pxToRem(100px);
        }

        @include breakpoint-for(desktop-extra-large) {
          line-height: 100px;
          font-size: pxToRem(127px);
        }
      }
    }

    .circular-text-container {
      align-items: center;
      display: flex;
      height: 150px;
      justify-content: center;
      margin-top: 160px;
      position: relative;
      width: 150px;

      @include breakpoint-for(tablet) {
        margin-top: 120px;
      }

      .circle-svg {
        animation: rotate-text 10s linear infinite;
        height: 100%;
        transform-origin: center;
        width: 100%;
      }

      .circle-text {
        fill: black;
        font-family: $madeoutersans_regular;
        font-size: pxToRem(11px);
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      .arrow {
        position: absolute;
        width: 30px;
      }

      @keyframes rotate-text {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .agility-container {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 200px;
      width: 100%;
      box-sizing: border-box;
      gap: 60px;

      @include breakpoint-for(tablet) {
        gap: 20px;
        flex-direction: row;
      }

      .text-agility {
        max-width: 620px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @include breakpoint-for(tablet) {
          width: 50%;
        }

        h3 {
          font-family: $madeoutersans_light;
          font-size: pxToRem(30px);
          font-weight: 300;
          text-align: left;
          color: black;
          margin: 0 0 73px 0;

          @include breakpoint-for(desktop-extra-large) {
            font-size: pxToRem(44px);
          }
        }

        p {
          font-family: $madeoutersans_regular;
          font-size: pxToRem(14px);
          text-align: left;
          line-height: 22px;
          margin: 15px 0;

          @include breakpoint-for(desktop-extra-large) {
            line-height: 28px;
            font-size: pxToRem(18px);
          }
        }

        .Button {
          margin-top: 90px;
        }
      }

      .group-info {
        position: relative;

        @include breakpoint-for(tablet) {
          width: 50%;
        }

        .font {
          cursor: pointer;
        }

        img {
          width: 100%;
          object-fit: cover;
          max-height: 443px;

          @include breakpoint-for(tablet) {
            min-width: 450px;
          }
        }

        p {
          font-family: $madeoutersans_regular;
          color: black;
          font-size: pxToRem(17px);
          border-bottom: 2px solid #70707069;
          width: max-content;
        }

        .right-flutuant-text {
          color: red;
          font-family: $madeoutersans_regular;
          font-size: pxToRem(27px);
          border: unset;
          position: absolute;

          @include breakpoint-for(desktop-small) {
            transform: matrix(0, -1, 1, 0, 0, 0);
            right: -200px;
            bottom: 180px;
          }
        }
      }
    }
  }
}
