@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.ForPeople {
  align-items: center;
  background-color: $background_primary_dark_color;
  display: flex;
  flex-direction: column;
  gap: 44px;
  justify-content: center;
  min-height: 100vh;
  padding: $nav_height $base_padding_mobile;

  @include breakpoint-for(desktop-small) {
    flex-direction: row;
  }

  svg {
    display: none;
    height: 360px;
    right: -170px;
    top: 200px;
    visibility: hidden;
    width: 360px;
    z-index: 0;
    
    @include breakpoint-for(tablet) {
      display: flex;
      visibility: visible;
    }

    @include breakpoint-for(desktop-large) {
      right: -130px;
    }
  }
}
