@import "../../../assets/scss/variables";

.Select {
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  label {
    color: white;
    font-family: $primary_font_book;
    font-weight: 400;
    line-height: $line-height;
  }

  select {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.55);
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    font-size: $base_font_size;
    outline: none;
    padding: 15px 35px 15px 0;
    transition: 0.2s;
    width: 100%;

    &.show-error {
      border-color: $error;
      color: $error;
    }
  }

  option {
    background-color: $background_primary_dark_color;
    border: none;
    color: white;
    font-family: $primary_font_book;
    font-size: $base_font_size;
    font-style: italic;
    opacity: 0.4;
    outline: none;

    &:disabled {
      color: grey;
    }
  }
}
