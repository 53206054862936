@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.HolderPortal {
  display: flex;
  justify-content: center;
  min-height: 780px;
  padding-top: $nav_height_mobile;
  
  @include breakpoint-for(desktop-small) {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: $nav_height;
  }

  .container-holder-portal {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 80%;

    .feedback-container {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 80%;

      .feedback {
        color: $error;
        font-family: $primary_font_book;
        font-size: 2em;
        font-weight: 400;
        margin-bottom: 0;
        width: 100%;
      }

      @include breakpoint-for(desktop-small) {
        margin-bottom: 10px;
        text-align: center;
        width: 80%;
      }
    }

    .form-holder-portal {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 80%;

      form {
        align-items: center;
        display: flex;
        flex-direction: column;

        .holder-dpo {
          margin-bottom: 50px;

          @include breakpoint-for(desktop-small) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 95%;
          }

          h2 {
            width: 100%;

            @include breakpoint-for(desktop-small) {
              width: 60%;
            }
          }
        }

        .info-holder {
          text-align: center;
          width: 100%;

          p {
            color: gray;
            font-size: 0.9rem;
            margin: 0;
          }
        }
      }

      h2 {
        font-family: $primary_font_heavy;
        font-size: 1.3rem;
        margin-bottom: 50px;
        width: 60%;
      }

      input {
        border: none;
        border-bottom: 2px solid #0d0d0d38;
        color: black;
        font-size: 1.2rem;
        font-weight: 700;
        height: 40px;
        margin-bottom: 20px;
        outline: none;
        width: 100%;
      }

      .Select {
        select {
          background-color: rgba(226, 226, 226, 0.418);
          border: none;
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
          color: $text_primary_dark_color;
          font-size: inherit;
          font-weight: 400;
          height: 55px;
          margin-top: 10px;
          margin-bottom: 30px;
          outline: none;
          width: 102%;
        }
      }

      textarea {
        background-color: #ffffff;
        border: 2px solid #0d0d0d38;
        color: black;
        font-family: SourceSansPro, sans-serif;
        font-size: 1.2rem;
        font-weight: 700;
        height: 80px;
        margin-bottom: 60px;
        padding: 5px;
        width: 100%;

        &::placeholder {
          font-size: 1.3rem;
          padding: 5px;
        }
      }

      button {
        background-color: unset;
        border: none;
        border-bottom: 1px solid #e50000;
        color: #e50000;
        font-family: $primary_font_demi;
        font-size: 1.2rem;
        margin: 20px;
        outline: none;
      }

      @include breakpoint-for(desktop-small) {
        form {
          display: flex;
          justify-content: center;
        }

        h2 {
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 50px;
          width: 97%;
        }

        .Select {
          padding-left: 31px;
          width: 100%;

          select {
            padding-left: 20px;
            width: 97%;
          }
        }

        input {
          background-color: rgba(226, 226, 226, 0.418);
          border: none;
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
          color: $text_primary_dark_color;
          font-size: 1.2rem;
          font-weight: 400;
          height: 20px;
          letter-spacing: 0.052rem;
          margin-top: 20px;
          outline: none;
          padding: 20px;
          width: 93%;
        }

        textarea {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
          padding-left: 20px;
          width: 94%;
        }

        button {
          background-color: #e50000;
          border: 2px solid #e50000;
          color: white;
          cursor: pointer;
          font-weight: 600;
          margin-bottom: 30px;
          padding: 9px;
        }
      }
    }
  }
}
