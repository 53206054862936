@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.ForCompanies {
  padding-top: $nav_height_mobile;
  
  @include breakpoint-for(tablet) {
    padding-top: $nav_height;
  }

  .filters {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 56px $base_padding_mobile 110px;
    width: 100%;
    
    @include breakpoint-for (tablet) {
      gap: 20px;
    }

    @include breakpoint-for (desktop-small) {
      gap: 10px;
      padding: 56px $base_padding 110px;
    }

    @include breakpoint-for (desktop-large) {
      gap: 30px;
      padding: 56px $base_padding_desktop_large 110px;
    }

    h3 {
      font-family: $primary_font_medium;
      font-size: $xxs_title_font_size;
      font-weight: normal;
      margin: 0 0 29px;
      order: 1;
      
      @include breakpoint-for (tablet) {
        margin: 0;
        order: unset;
      }

      @include breakpoint-for (desktop-small) {
        font-size: $xxxxs_title_font_size;
      }

      @include breakpoint-for (desktop-large) {
        font-size: $xxs_title_font_size;
      }
    }

    .filter-group {
      align-items: center;
      display: flex;
      gap: 10px;
      justify-content: center;

      &:nth-child(2) {
        order: 3;

        @include breakpoint-for (tablet) {
          order: unset;
        }
      }
      
      &:nth-child(3) {
        order: 4;

        @include breakpoint-for (tablet) {
          order: unset;
        }
      }
      
      @include breakpoint-for (desktop-small) {
        gap: 13px;
      }
      
      p {
        font-size: $base_font_size;
        font-family: $primary_font_book;
      }
      
      .filter-group-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        
        @include breakpoint-for (desktop-small) {
          gap: 13px;
        }
      }
    }

    .SearchInput {
      margin-top: 10px;
      order: 2;
      width: 100%;

      @include breakpoint-for (desktop-small) {
        flex-grow: 1;
        margin-top: unset;
        order: unset;
        width: unset;
      }
    }
  }

  section {
    background-color: $background_primary_light_color;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: flex-start;
    margin-bottom: 12px;
    position: relative;
    width: 100%;
    z-index: 2;

    .no-course {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 56px $base_padding_mobile 110px;

      @include breakpoint-for (desktop-small) {
        padding: 56px 120px 110px;
      }

      h3 {
        font-family: $primary_font_heavy;
        font-size: $lg_title_font_size;
        margin: 0 0 46px;
        text-align: left;
      }

      p {
        font-family: $primary_font_book;
        font-size: $base_font_size;
        text-align: left;
      }
    }
  }
}
