@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.TermsConditions {
  min-height: calc(100vh - $nav_height);
  padding: $nav_height 30px;

  @include breakpoint-for(desktop-small) {
    padding: $nav_height 50px 50px;
  }

  & > h2 {
    font-family: $primary_font_heavy;
    font-size: 3.3rem;
    font-weight: 400;
    margin: 30px 0;

    @include breakpoint-for(tablet) {
      font-size: 5rem;
    }

    @include breakpoint-for(desktop-small) {
      font-size: 6rem;
    }
    @include breakpoint-for(desktop-large) {
      margin-left: 4.6%;
    }
  }

  & > div {
    @include breakpoint-for(desktop-small) {
      display: flex;
      justify-content: space-between;
    }

    @include breakpoint-for(desktop-large) {
      justify-content: space-around;
    }
    div {
      @include breakpoint-for(desktop-small) {
        width: 45%;
      }

      @include breakpoint-for(desktop-large) {
        width: 40%;
      }
      p {
        color: $text_primary_dark_color;
        font-family: $primary_font_book;
        font-size: $base_font_size;
        line-height: 1.75rem;

        @include breakpoint-for(desktop-small) {
          text-align: justify;
        }
        span {
          font-weight: 600;
        }
      }
    }
  }
}
