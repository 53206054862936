@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.CardEcommerce {
  display: flex;
  flex-direction: column;
  gap: 25px 0;
  margin-top: 25px;

  .input-box {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    label {
      font-family: $primary_font_book;
      font-size: 18px;
      margin: 9px 0;
    }

    input {
      border: 1px solid #707070;
      font-family: $primary_font_book;
      font-size: 20px;
      height: 60px;
      padding: 14px 10px;
      width: 100%;

      &.error {
        border: 1px solid $error;
        outline: none;
      }
    }

    p {
      color: $error;
      font-family: $primary_font_book;
      margin: 5px 0 0 0;
    }

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      background: transparent;
      background: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') 97% 50% no-repeat transparent;
      border: 1px solid #707070;
      font-family: $primary_font_book;
      font-size: 26px;
      height: 60px;
      padding: 14px 20px;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        width: 65%;
      }
    }
  }

  .input-box-two {
    align-items: flex-end;
    display: flex;
    gap: 0 13px;

    label {
      text-align: left;
    }

    .fake-input {
      align-items: center;
      border: 1px solid #707070;
      display: flex;
      flex-direction: row;
      justify-content: center;
      max-width: 70%;
      padding: 0;

      input {
        border: unset;
      }

      .seperator {
        color: #ccc;
        font-size: 20px;
        left: 25%;
        position: relative;
        top: 0;
      }

      @include breakpoint-for(desktop-small) {
        width: 50%;

        input {
          border: unset;
          padding: 25px;
          width: 50%;
        }
      }
    }

    div {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      width: 100%;

      label {
        font-family: $primary_font_book;
        font-size: 18px;
        margin: 9px 0;
      }

      input {
        border: 1px solid #707070;
        font-family: $primary_font_book;
        font-size: 20px;
        height: 60px;
        padding: 14px 10px;
        width: 100%;
      }

      &.code-cvc {
        width: 80%;
      }
    }
  }

  .input-box-three {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 0 13px;

    @include breakpoint-for(desktop-small) {
      align-items: flex-start;
      flex-direction: row;
    }

    label {
      text-align: left;
    }

    div {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      width: 100%;

      label {
        font-family: $primary_font_book;
        font-size: 18px;
        margin: 9px 0;
      }

      input {
        border: 1px solid #707070;
        font-family: $primary_font_book;
        font-size: 20px;
        height: 60px;
        padding: 14px 10px;
        width: 100%;
      }
    }
  }

  .installments-container {
    .initial-installments {
      display: flex;

      p {
        font-family: $primary_font_book;
        font-size: 18px;
        margin: 9px 0;

        span {
          font-family: $primary_font_demi;
          font-size: 25px;
          margin-left: 10px;
        }
      }

      button {
        background: none;
        border: none;
        color: inherit;
        cursor: pointer;
        font-family: $primary_font_book;
        font-size: 13px;
        margin-left: 15px;
        outline: inherit;
        padding: 0;
        text-decoration: underline;
      }
    }

    .payment-button {
      display: flex;
      font-family: $primary_font_demi;
      font-size: 20px;
      height: 70px;
      justify-content: center;
      margin-top: 50px;
      width: 100%;
    }
  }
}
