@import "./../../assets/scss/mixins";
@import "./../../assets/scss/variables";

.PasswordRecovery {
  align-items: center;
  background-color: $background_primary_dark_color;
  color: $background_primary_light_color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 0 20px;

  & > form {
    display: none;
    flex-direction: column;
    margin-bottom: 50px;

    &.visibleForm {
      display: flex;
    }

    @include breakpoint-for(tablet) {
      margin: 0;
    }

    & > h4 {
      color: white;
      font-family: $primary_font_heavy;
      font-size: $base_font_size;
      padding-bottom: 60px;
    }

    & > div {
      &:last-child {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 50px;
        width: 100%;

        button {
          background-color: transparent;
          border: none;
          border-bottom: 1px solid transparent;
          cursor: pointer;
          outline: none;
          padding: 0 0 2px;

          &:first-child {
            color: white;

            &:hover {
              border-color: white;
            }
          }

          &:last-child {
            border-color: $error;
            color: $error;
            font-size: $base_font_size;
          }
        }
      }
    }
  }

  & > .message {
    display: none;
    flex-direction: column;

    &.visible {
      display: flex;
    }
  }
}
